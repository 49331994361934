import axios from "redaxios";
import store from ".";
import db from "../models";

const tableNames = [
  "fische",
  "kunden",
  "lieferanten",
  "laendernamen",
  "wareneinheiten",
  "fanggebiete",
  "fangmethoden",
  "filialen",
  "fischeinheiten",
  "fischkennzeichnungen",
  "verbrauchswaren",
  "bestellungen",
  "einkauf"
];

let lastEtag;

/**
 * fetch all data from all tables and populate the initial state of the store
 */
export async function initStore() {
  if (!isFinite(store.state.userlevel)) {
    return Promise.resolve();
  }
  try {
    const tables =
      store.state.userlevel <= 3
        ? [...tableNames, "users", "mitarbeiter"]
        : tableNames;
    let currentEtag = {};
    if (lastEtag) {
      const etags = await Promise.all(
        tables.map(async table => {
          const { headers } = await axios.head(`/api/${table}`);
          return headers.get("etag") || lastEtag[table];
        })
      );
      currentEtag = etags.reduce((acc, etag, i) => {
        acc[tables[i]] = etag;
        return acc;
      }, {});
    }
    const staleTables = lastEtag
      ? tables.filter(table => currentEtag[table] !== lastEtag[table])
      : tables;
    const storePromises = staleTables.map(async table => {
      const etag = await loadTable(table);
      currentEtag[table] = etag;
    });
    await Promise.all(storePromises);
    lastEtag = currentEtag;
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
}

export async function loadTable(tableName) {
  try {
    const table = db[tableName];
    const currentRecords = table.all();
    const { data, headers } = await axios.get(`/api/${tableName}`);
    if (currentRecords.length === 0) {
      await table.insert({ data });
      return headers.get("etag");
    }
    const incomingRecordsById = data.reduce((acc, record) => {
      acc[record.id] = record;
      return acc;
    }, {});
    const updates = [];
    const deletes = [];
    for (let i = 0, ii = currentRecords.length; i < ii; ++i) {
      const currentRecord = currentRecords[i];
      const id = currentRecord.id;
      const incomingRecord = incomingRecordsById[id];
      if (incomingRecord) {
        if (currentRecord.updatedAt < incomingRecord.updatedAt) {
          updates.push(incomingRecord);
        }
        delete incomingRecordsById[id];
      } else {
        deletes.push(currentRecord);
        delete incomingRecordsById[id];
      }
    }
    const inserts = Object.values(incomingRecordsById);
    await Promise.all(deletes.map(({ id }) => table.delete(id)));
    await table.update({ data: updates });
    await table.insert({ data: inserts });
    return headers.get("etag");
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
}
