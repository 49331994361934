import { VCol, VIcon, VRow, VBtn } from "vuetify/lib";
import "../mobileClasses.scss";

export default {
  data() {
    return {
      currentDate: this.$store.state.bestellungConfig.datum,
      today: new Date(),
      currentOffset: 0,
      locales: { de: "de-DE", en: "en-GB", si: "sl-SL", it: "it-IT" }
    };
  },
  created() {},
  computed: {
    rowCalendar() {
      const formatDay = new Intl.DateTimeFormat(
        this.locales[this.$route.params.lang],
        { weekday: "short" }
      );
      const row = [];
      for (let d = 0; d < 7; d++) {
        const rowDate = this.addDays(
          this.currentDate,
          d + this.currentOffset - this.currentDate.getDay()
        );
        row.push({
          date: rowDate,
          weekday: formatDay.format(rowDate),
          daymonth:
            rowDate.getDate().toString().padStart(2, "0") +
            "." +
            (rowDate.getMonth() + 1).toString().padStart(2, "0") +
            "." +
            (rowDate.getYear() + 1900).toString().substr(2, 2)
        });
      }
      return row;
    }
  },
  methods: {
    setWeek(days) {
      this.currentOffset += days;
      this.setDate(this.rowCalendar[1].date);
    },
    goToday() {
      const today = new Date();
      this.setDate(today);
      this.$store.commit("bestellungConfig", {
        key: "store",
        value: this.$store.state.filiale
          ? this.$store.state.filiale
          : this.$store.state.bestellungConfig.store
      });
    },
    addDays(date, days) {
      const copy = new Date(Number(date));
      copy.setDate(date.getDate() + days);
      return copy;
    },
    setDate(datum) {
      this.$store.commit("bestellungConfig", {
        key: "datum",
        value: datum
      });
      this.currentDate = datum;
      this.currentOffset = 0;
    }
  },
  render() {
    const dayBbuttons = this.rowCalendar.map(item => {
      return (
        <VBtn
          width="95"
          height="45"
          class={item.date.getDay() == 0 ? "mx-3 halfvisible" : "mx-3"}
          color={
            this.currentDate.toString() == item.date.toString()
              ? "rgb(0,113,113)"
              : item.date.toDateString() == new Date().toDateString()
              ? "rgb(255,255,204)"
              : "white"
          }
          dark={
            this.currentDate.toString() == item.date.toString() ? true : false
          }
          onClick={() => this.setDate(item.date)}
        >
          {item.weekday}
          <br />
          {item.daymonth}
        </VBtn>
      );
    });
    return (
      <VRow no-gutters>
        <VCol cols="1" class="text-left">
          <VBtn
            width="45"
            height="45"
            color="white"
            onClick={() => this.goToday()}
          >
            <VIcon large>mdi-home-circle-outline</VIcon>
          </VBtn>
        </VCol>
        <VCol cols="11" class="text-right">
          <VBtn
            width="45"
            height="45"
            fab
            onClick={() => this.setWeek(-7)}
            class="mr-3"
          >
            <VIcon>mdi-arrow-left-thick</VIcon>
          </VBtn>
          {dayBbuttons}
          <VBtn
            width="45"
            height="45"
            fab
            onClick={() => this.setWeek(7)}
            class="ml-3"
          >
            <VIcon>mdi-arrow-right-thick</VIcon>
          </VBtn>
        </VCol>
      </VRow>
    );
  }
};
