import { VForm, VTextField, VCard, VBtn } from "vuetify/lib";
import axios from "axios";

export default {
  mounted() {
    this.$refs.focusMe.focus();
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      try {
        await axios.post("/api/login", {
          username: this.username,
          password: this.password
        });
      } catch (error) {
        this.$refs.form.reset();
        this.$refs.focusMe.focus();
      }
    }
  },
  render() {
    return (
      <div class="pt-5 d-flex justify-center">
        <VCard width="340px" height="240px" class="pa-5" elevation="4">
          <VForm ref="form" onSubmit={this.submit}>
            <VTextField
              ref="focusMe"
              onInput={e => (this.username = e)}
              label={this.$t("label.username")}
              placeholder={this.$t("label.username")}
              single
              outlined
              required
            />
            <VTextField
              onInput={e => (this.password = e)}
              label={this.$t("label.password")}
              placeholder={this.$t("label.password")}
              required
              single
              outlined
              type="password"
            />
            <VBtn type="submit" color="primary">
              {this.$t("button.login")}
            </VBtn>
          </VForm>
        </VCard>
      </div>
    );
  }
};
