import {
  VForm,
  VCard,
  VRow,
  VCol,
  VBtn,
  VIcon,
  VTextField,
  VTextarea,
  VSelect,
  VDialog,
  VDatePicker,
  VCardTitle
} from "vuetify/lib";

import "../detailTables.scss";
import { v4 as uuidv4 } from "uuid";
import Bestellungen from "@/models/bestellungen";
import Fischeinheiten from "@/models/fischeinheiten";
import Wareneinheiten from "@/models/wareneinheiten";
import Fischkennzeichnungen from "@/models/fischkennzeichnungen";
import Fische from "@/models/fisch";
import Filialen from "@/models/filialen";
import Kunden from "@/models/kunden";
import KundenAuswahl from "./components/kundenAuswahl";
import FischAuswahl from "./components/fischAuswahl";
import WareAuswahl from "./components/wareAuswahl";
import bindings from "@/store/bindingsperid";
import { persistItem, deleteItem } from "@/utils/helpers";
import "./mobileClasses.scss";

export default {
  data() {
    return {
      // this will automatically be updated by the setters of v-model properties
      ...bindings(Bestellungen, this.$route.params.id),
      alldata: [
        "createdAt",
        "id",
        "filiale",
        "datum",
        "filialeabholung",
        "datumabholung",
        "mitarbeiter",
        "typ",
        "fischeinheit",
        "fisch",
        "wareneinheit",
        "produkt",
        "kennzeichnungen",
        "bezahlung",
        "anzahl",
        "anzahlpersonen",
        "hauptneben",
        "gewicht",
        "gewichtbis",
        "kunde",
        "kundedirekt",
        "kundekontakt",
        "rueckruf",
        "zustellung",
        "status",
        "bestellnummer",
        "bestellnummerkurz",
        "lagernummer",
        "lagernummerzusatz",
        "anmerkung"
      ],
      lagerNummerReset: false,
      dirty: false,
      modal: false,
      closemodal: false,
      deletemodal: false,
      goodsChoose: false,
      fishChoose: false,
      customerChoose: false,
      optionsDate: { year: "numeric", month: "2-digit", day: "2-digit" },
      optionsTime: { hour: "2-digit", minute: "2-digit" },
      optionsDateTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
      },
      jsDatum: null,
      jsTime: null,
      fischKZGefiltert: null,
      fischKZIDs: [],
      fischKZSelected: [],
      fischObjekt: null,
      kundenObjekt: null,
      timeArray: [],
      nachgewicht: false,
      bezahlungValues: ["BAR", "KARTE"],
      hauptnebenValues: ["KA", "H", "N"],
      hauptnebenLabels: ["K.Angabe", "Haupt", "Neben"],
      filialen: [],
      defaults: {
        filiale: this.$store.state.bestellungConfig.store,
        filialeabholung: this.$store.state.bestellungConfig.store,
        datum: new Date().toISOString(),
        typ: "fisch",
        kunde: this.$store.state.bestellungConfig.customer,
        fisch: this.$store.state.bestellungConfig.fish,
        status: "VB",
        bezahlung: "BAR",
        hauptneben: "KA",
        anzahl: 1,
        gewicht: 0,
        gewichtbis: 0,
        kennzeichnungen: null
      }
    };
  },
  computed: {
    HNLabel() {
      const aktIndex = this.hauptnebenValues.indexOf(this.hauptneben);
      return this.hauptnebenLabels[aktIndex];
    },

    derkunde() {
      return this.kundenObjekt
        ? (this.kundenObjekt.nachname && this.kundenObjekt.nachname.length > 0
            ? this.kundenObjekt.nachname
            : "") +
            (this.kundenObjekt.vorname && this.kundenObjekt.vorname.length > 0
              ? " " + this.kundenObjekt.vorname
              : "")
        : "";
    },
    dieware() {
      return this.typ == "ware" && this.wareneinheit && this.wareneinheit != ""
        ? Wareneinheiten.find(this.wareneinheit).bezeichnung
        : "";
    },
    derfisch() {
      return this.typ != "ware" && this.fischObjekt
        ? this.fischObjekt["name_" + this.$route.params.lang]
        : "";
    },
    existingOrders() {
      const exOrd = Bestellungen.query()
        .where(order => {
          return order.bestellnummer == this.bestellnummer;
        })
        .get();
      return exOrd;
    },

    displayDate() {
      return new Date(this.datumabholung).toLocaleDateString(
        this.$route.params.lang,
        this.optionsDateTime
      );
    },
    rules() {
      return {
        weightrangefrom: () => {
          return !this.nachgewicht || this.gewicht ? true : false;
        },
        weightrangeto: () => {
          return this.gewichtbis &&
            parseInt(this.gewicht) <= parseInt(this.gewichtbis)
            ? true
            : false;
        },
        datecheck: () => {
          return new Date(this.datumabholung) < new Date() ? false : true;
        },
        required: value => !!value || this.$t("error.obligatory"),
        plz: value =>
          (value >= 1000 && value <= 9999) || this.$t("error.areacode"),
        minlength: value => value.length > 4 || this.$t("error.chars5min"),
        email: value => {
          if (value) {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (
              pattern.test(value) ||
              value.length === 0 ||
              this.$t("error.invalidmail")
            );
          } else {
            return true;
          }
        }
      };
    }
  },
  created() {
    this.$store.commit("bestellungConfig", {
      key: "currentid",
      value: this.$route.params.id
    });

    if (
      this.$store.state.bestellungConfig.ordermode != "fish" &&
      this.$store.state.bestellungConfig.ordermode != "goods"
    ) {
      this.$router.push({
        path: "/" + this.$route.params.lang + "/mobile/verkauf"
      });
    }
    if (!this.datumabholung) {
      let tempDate = this.$store.state.bestellungConfig.datum;
      let startHour = 7;
      let now = new Date();
      if (
        10000 * tempDate.getFullYear() +
          100 * (tempDate.getMonth() + 1) +
          tempDate.getDate() ==
        10000 * now.getFullYear() + 100 * (now.getMonth() + 1) + now.getDate()
      ) {
        startHour = now.getHours() + 2;
      }
      tempDate.setHours(startHour, 0, 0);
      this.datumabholung = tempDate.toISOString();
    }

    if (this.$store.state.bestellungConfig.rendermode == "neworder") {
      if (this.$store.state.bestellungConfig.customer != "") {
        this.customerChosen();
      }

      this.$store.commit("bestellungConfig", {
        key: "lagernummergesamt",
        value: ""
      });
      this.bestellnummer = uuidv4();
      const tempDate = new Date();
      this.bestellnummerkurz =
        tempDate.getDay().toString().padStart(2, "0") +
        Math.floor(
          (tempDate.getHours() * 3600 +
            tempDate.getMinutes() * 60 +
            tempDate.getSeconds()) /
            10
        );
    }
    if (this.lagernummer && Number(this.lagernummer) > 0) {
      this.$store.commit("bestellungConfig", {
        key: "lagernummergesamt",
        value:
          this.lagernummerzusatz + this.lagernummer.toString().padStart(3, "0")
      });
    }
    if (!Bestellungen.find(this.$store.state.bestellungConfig.currentid)) {
      for (let field in this.defaults) {
        this[field] = this.defaults[field];
      }
      this.$store.commit("bestellungConfig", {
        key: "storecollection",
        value: this.filialeabholung
      });
    } else {
      this.$store.commit("bestellungConfig", {
        key: "fish",
        value: this.fisch
      });
      this.$store.commit("bestellungConfig", {
        key: "consumable",
        value: this.wareneinheit
      });
      if (this.$store.state.bestellungConfig.ordermode == "goods") {
        this.goodsChosen();
      } else {
        this.fishChosen();
      }
      this.$store.commit("bestellungConfig", {
        key: "customer",
        value: this.kunde
      });
      this.customerChosen();
      this.fischKZSelected =
        this.kennzeichnungen && this.kennzeichnungen != ""
          ? JSON.parse(this.kennzeichnungen)
          : [];
    }

    if (
      this.$store.state.bestellungConfig.rendermode == "additem" &&
      this.$store.state.bestellungConfig.lastid.length > 1
    ) {
      this.dirty = true;
      const lastorder = Bestellungen.find(
        this.$store.state.bestellungConfig.lastid
      );
      this.status = "VB";
      this.bestellnummer = lastorder.bestellnummer;
      this.bestellnummerkurz = lastorder.bestellnummerkurz;
      this.filialeabholung = lastorder.filialeabholung;
      this.bezahlung = lastorder.bezahlung;
      this.rueckruf = lastorder.rueckruf;
      this.zustellung = lastorder.zustellung;
      this.datumabholung = lastorder.datumabholung;
      this.anzahlpersonen = lastorder.anzahlpersonen;
      this.hauptneben = lastorder.hauptneben;
      this.anmerkung = lastorder.anmerkung;
      this.lagernummer = lastorder.lagernummer;
      this.lagernummerzusatz = lastorder.lagernummerzusatz;
      this.$store.commit("bestellungConfig", {
        key: "customer",
        value: lastorder.kunde
      });
      this.customerChosen();
    }
    this.$store.commit("bestellungConfig", {
      key: "bestellnummer",
      value: this.bestellnummer
    });
    this.$store.commit("bestellungConfig", {
      key: "bestellnummerkurz",
      value: this.bestellnummerkurz
    });
    for (let Stunde = 0; Stunde < 13; Stunde++) {
      this.timeArray.push((7 + Stunde).toString().padStart(2, "0") + ":00");
    }
    this.jsDatum = new Date(this.datumabholung).toISOString().split("T")[0];
    this.jsTime = new Date(this.datumabholung).toLocaleTimeString(
      this.$route.params.lang,
      this.optionsTime
    );
    Filialen.all().forEach(element => {
      if (element.verkauf) {
        this.filialen.push({ text: element.name, value: element.id });
      }
    });

    if (this.$store.state.bestellungConfig.quicksave) {
      for (let attrib in this.$store.state.bestellungConfig.quicksave) {
        this[attrib] = this.$store.state.bestellungConfig.quicksave[attrib];
      }
      if (this.wareneinheit) {
        this.goodsChosen();
      }
      if (this.fisch) {
        this.fishChosen();
      }
      if (this.kunde) {
        this.kundenObjekt = Kunden.query().where("id", this.kunde).get()[0];
      }
      this.dirty = true;
    }

    if (
      !this.$store.state.bestellungConfig.quicksave &&
      (this.$store.state.bestellungConfig.rendermode == "neworder" ||
        this.$store.state.bestellungConfig.rendermode == "additem")
    ) {
      if (this.$store.state.bestellungConfig.ordermode == "fish") {
        this.fishChoose = true;
      }
      if (this.$store.state.bestellungConfig.ordermode == "goods") {
        this.goodsChoose = true;
      }
    }
  },
  methods: {
    alterWithCheck(what, justcheck) {
      if (what == "rueckruf") {
        if ((!this.rueckruf && !justcheck) || (justcheck && this.rueckruf)) {
          if (!this.kunde) {
            this.$root.$confirm(this.$t("dialog.nocustomer"), {
              icon: "mdi-warning",
              title: this.$t("header.importantnotice")
            });
            this.rueckruf = false;
          } else {
            if (
              Kunden.find(this.kunde) &&
              Kunden.find(this.kunde).mobil.toString().length > 5
            ) {
              this.rueckruf = true;
            } else {
              this.$root.$confirm(this.$t("dialog.customerneedsmobile"), {
                icon: "mdi-warning",
                title: this.$t("header.importantnotice")
              });
              this.rueckruf = false;
            }
          }
        } else {
          if (!justcheck) {
            this.rueckruf = false;
          }
        }
      }
      if (what == "zustellung") {
        if (
          (!this.zustellung && !justcheck) ||
          (justcheck && this.zustellung)
        ) {
          if (!this.kunde) {
            this.$root.$confirm(this.$t("dialog.nocustomer"), {
              icon: "mdi-warning",
              title: this.$t("header.importantnotice")
            });
            this.zustellung = false;
          } else {
            if (
              Kunden.find(this.kunde) &&
              Kunden.find(this.kunde).mobil &&
              Kunden.find(this.kunde).mobil.toString().length > 5 &&
              Kunden.find(this.kunde).adresse &&
              Kunden.find(this.kunde).adresse.toString().length > 5
            ) {
              this.zustellung = true;
            } else {
              this.$root.$confirm(this.$t("dialog.customerneedsaddress"), {
                icon: "mdi-warning",
                title: this.$t("header.importantnotice")
              });
              this.zustellung = false;
            }
          }
        } else {
          if (!justcheck) {
            this.zustellung = false;
          }
        }
      }
    },
    maxNumber(filiale, datum) {
      const bestellungen = Bestellungen.query()
        .where(
          "datumabholung",
          value => value.substr(0, 10) == datum.substr(0, 10)
        )
        .where("filialeabholung", filiale)
        .get();
      bestellungen.sort((a, b) => {
        return a.lagernummer < b.lagernummer ? 1 : -1;
      });
      return bestellungen.length > 0 ? bestellungen[0].lagernummer + 1 : 1;
    },
    cancelEdit() {
      if (this.$store.state.bestellungConfig.rendermode == "neworder") {
        this.$router.push({
          path: "/" + this.$route.params.lang + "/mobile/verkauf"
        });
      } else {
        let id = this.$store.state.bestellungConfig.currentid;
        let what = this.typ == "ware" ? "goods" : "fish";
        if (this.$store.state.bestellungConfig.rendermode == "additem") {
          const lastorder = Bestellungen.find(
            this.$store.state.bestellungConfig.lastid
          );
          id = lastorder.id;
          what = lastorder.typ == "ware" ? "goods" : "fish";
        }
        this.dirty = false;
        this.goItem(id, what);
      }
    },
    customerEdit() {
      let newSave = {};
      for (let a = 0; a < this.alldata.length; a++) {
        newSave[this.alldata[a]] = this[this.alldata[a]];
      }
      newSave.id = this.$store.state.bestellungConfig.currentid;
      this.$store.commit("bestellungConfig", {
        key: "quicksave",
        value: newSave
      });

      this.$router.push({
        path:
          "/" +
          this.$route.params.lang +
          `/mobile/kunden/${this.$store.state.bestellungConfig.customer}`
      });
    },
    newCustomer() {
      let newSave = {};
      for (let a = 0; a < this.alldata.length; a++) {
        newSave[this.alldata[a]] = this[this.alldata[a]];
      }
      newSave.id = this.$store.state.bestellungConfig.currentid;
      this.$store.commit("bestellungConfig", {
        key: "quicksave",
        value: newSave
      });
      this.$router.push({
        path: "/" + this.$route.params.lang + `/mobile/kunden/${uuidv4()}`
      });
    },
    goItem(id, what) {
      this.nachgewicht = false;
      if (!this.dirty) {
        this.$store.commit("bestellungConfig", {
          key: "ordermode",
          value: what == "ware" ? "goods" : "fish"
        });
        this.$store.commit("bestellungConfig", {
          key: "lastid",
          value: id
        });
        this.$store.commit("bestellungConfig", {
          key: "currentid",
          value: id
        });

        const newvals = bindings(Bestellungen, id);
        Object.keys(newvals).forEach(key => {
          this[key] = newvals[key];
        });

        this.$store.commit("bestellungConfig", {
          key: "fish",
          value: this.fisch
        });
        this.$store.commit("bestellungConfig", {
          key: "consumable",
          value: this.wareneinheit
        });
        if (this.$store.state.bestellungConfig.ordermode == "goods") {
          this.goodsChosen();
        } else {
          this.fishChosen();
        }
        this.$store.commit("bestellungConfig", {
          key: "customer",
          value: this.kunde
        });
        this.customerChosen();
        this.fischKZSelected =
          this.kennzeichnungen && this.kennzeichnungen != ""
            ? JSON.parse(this.kennzeichnungen)
            : [];
      }
    },
    groupChange() {
      for (let index in this.existingOrders) {
        if (
          this.existingOrders[index].id !=
          this.$store.state.bestellungConfig.currentid
        ) {
          Bestellungen.api().put(
            `/api/bestellungen/${this.existingOrders[index].id}`,
            {
              bezahlung: this.bezahlung,
              zustellung: this.zustellung,
              rueckruf: this.rueckruf,
              kunde: this.kunde,
              filialeabholung: this.filialeabholung,
              datumabholung: this.datumabholung,
              hauptneben: this.hauptneben,
              anmerkung: this.anmerkung,
              anzahlpersonen: this.anzahlpersonen,
              lagernummer: this.lagernummer,
              lagernummerzusatz:
                Filialen.find(this.filialeabholung).kuerzel +
                this.datumabholung.substr(8, 2)
            },
            {
              headers: {
                "Content-Type": "application/json"
              }
            }
          );
        }
      }
    },
    addItem(what) {
      this.nachgewicht = false;
      this.eraseStateConfig("all");
      this.$store.commit("bestellungConfig", {
        key: "customer",
        value: this.kunde
      });
      this.$store.commit("bestellungConfig", {
        key: "ordermode",
        value: what
      });
      this.$store.commit("bestellungConfig", {
        key: "rendermode",
        value: "additem"
      });
      this.$store.commit("bestellungConfig", {
        key: "lastid",
        value: this.$store.state.bestellungConfig.currentid
      });

      const id = uuidv4();
      this.$store.commit("bestellungConfig", {
        key: "currentid",
        value: id
      });
      const newvals = bindings(Bestellungen, id);
      Object.keys(newvals).forEach(key => {
        this[key] = newvals[key];
      });

      this.dirty = true;
      const lastorder = Bestellungen.find(
        this.$store.state.bestellungConfig.lastid
      );
      for (let field in this.defaults) {
        this[field] = this.defaults[field];
      }
      this.fischKZSelected = [];

      this.bestellnummer = lastorder.bestellnummer;
      this.bestellnummerkurz = lastorder.bestellnummerkurz;
      this.filialeabholung = lastorder.filialeabholung;
      this.bezahlung = lastorder.bezahlung;
      this.rueckruf = lastorder.rueckruf;
      this.zustellung = lastorder.zustellung;
      this.datumabholung = lastorder.datumabholung;
      this.anzahlpersonen = lastorder.anzahlpersonen;
      this.hauptneben = lastorder.hauptneben;
      this.anmerkung = lastorder.anmerkung;
      this.lagernummer = lastorder.lagernummer;
      this.lagernummerzusatz = lastorder.lagernummerzusatz;
      this.$store.commit("bestellungConfig", {
        key: "customer",
        value: lastorder.kunde
      });
      this.customerChosen();

      this.filiale = this.$store.state.filiale;
      this.datum = this.$store.state.datum;

      if (this.$store.state.bestellungConfig.ordermode == "fish") {
        this.fishChoose = true;
      }
      if (this.$store.state.bestellungConfig.ordermode == "goods") {
        this.goodsChoose = true;
      }
    },
    closeEditor(force = false) {
      if (!force && this.dirty) {
        this.closemodal = true;
      } else {
        this.eraseStateConfig("all");
        this.$router.push({
          path: "/" + this.$route.params.lang + "/mobile/verkauf"
        });
      }
    },
    eraseStateConfig(mode) {
      this.$store.commit("bestellungConfig", {
        key: "quicksave",
        value: null
      });
      this.$store.commit("bestellungConfig", {
        key: "ordermode",
        value: ""
      });
      this.$store.commit("bestellungConfig", {
        key: "rendermode",
        value: ""
      });
      this.$store.commit("bestellungConfig", {
        key: "fish",
        value: ""
      });
      this.$store.commit("bestellungConfig", {
        key: "customer",
        value: ""
      });
      this.$store.commit("bestellungConfig", {
        key: "bestellnummer",
        value: ""
      });
      this.$store.commit("bestellungConfig", {
        key: "bestellnummerkurz",
        value: ""
      });
      if (mode == "all") {
        this.$store.commit("bestellungConfig", {
          key: "datecollection",
          value: ""
        });
        this.$store.commit("bestellungConfig", {
          key: "storecollection",
          value: ""
        });
      }
    },
    customerChosen() {
      this.kunde = this.$store.state.bestellungConfig.customer;
      this.kundenObjekt = Kunden.query().where("id", this.kunde).get()[0];
      this.customerChoose = false;
      this.alterWithCheck("rueckruf", true);
      this.alterWithCheck("zustellung", true);
    },
    goodsChosen() {
      this.wareneinheit = this.$store.state.bestellungConfig.consumable;
      this.typ = "ware";
      this.goodsChoose = false;
    },
    fishChosen() {
      this.fisch = this.$store.state.bestellungConfig.fish;
      this.fischObjekt = Fische.query().where("id", this.fisch).get()[0];
      this.nachgewicht = this.fischObjekt
        ? this.fischObjekt["nachgewicht"]
        : false;
      this.fischKZIDs = [];
      Fischeinheiten.query()
        .where("fisch", this.fisch)
        .get()
        .forEach(element => {
          this.fischKZIDs = this.fischKZIDs.concat(
            JSON.parse(element.fischkennzeichnungen)
          );
        });
      this.fischKZGefiltert = Fischkennzeichnungen.query()
        .whereIdIn(this.fischKZIDs)
        .get();
      this.fischKZGefiltert.sort((a, b) => {
        let ret = 0;
        if (a.gruppe > b.gruppe) {
          ret = 1;
        }
        if (a.gruppe < b.gruppe) {
          ret = -1;
        }
        if (a.gruppe == b.gruppe) {
          if (a.bezeichnung > b.bezeichnung) {
            ret = 1;
          } else {
            ret = -1;
          }
        }
        return ret;
      });
      this.typ = "fisch";
      this.fishChoose = false;
    },
    toggleHauptNeben() {
      let aktIndex = this.hauptnebenValues.indexOf(this.hauptneben);
      aktIndex = (aktIndex + 1) % 3;
      this.hauptneben = this.hauptnebenValues[aktIndex];
    },
    dateSave() {
      this.datumabholung = new Date(
        this.jsDatum + "T" + this.jsTime.substr(0, 2) + ":00"
      ).toISOString();
      this.$store.commit("bestellungConfig", {
        key: "datecollection",
        value: this.datumabholung
      });
      this.modal = false;
      if (this.lagernummer) {
        this.lagerNummerReset = true;
      }
      this.lagernummer = null;
    },
    kzSelect(id) {
      if (!this.fischKZSelected.includes(id)) {
        this.fischKZSelected.push(id);
      } else {
        this.fischKZSelected = this.fischKZSelected.filter(e => e != id);
      }
      this.kennzeichnungen = JSON.stringify(this.fischKZSelected);
    },
    delete() {
      const id = this.$store.state.bestellungConfig.currentid;
      deleteItem(Bestellungen, id, "bestellungen");
      this.deletemodal = false;
      if (this.existingOrders && this.existingOrders.length > 0) {
        this.goItem(this.existingOrders[0].id, this.existingOrders[0].typ);
      } else {
        this.eraseStateConfig("all");
        this.$router.push({
          path: "/" + this.$route.params.lang + "/mobile/verkauf"
        });
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        const id = this.$store.state.bestellungConfig.currentid;
        this.lagernummerzusatz =
          (Filialen.find(this.filialeabholung).kuerzel
            ? Filialen.find(this.filialeabholung).kuerzel
            : "") + this.datumabholung.substr(8, 2);

        if (this.lagerNummerReset) {
          this.$root.$confirm(
            "Durch Änderung von Filiale oder Abholdatum wurde die Bestellnummer neu vergeben!",
            {
              buttonFalseText: "",
              icon: false,
              title: "Neue Bestellnummer"
            }
          );
          this.lagerNummerReset = false;
        }

        if (!this.lagernummer || this.lagernummer == 0) {
          this.lagernummer = this.maxNumber(
            this.filialeabholung,
            this.datumabholung
          );
        }

        if (!this.datum) {
          this.datum = new Date().toISOString();
        }
        persistItem(Bestellungen, id, this, "bestellungen");
        this.$store.commit("bestellungConfig", {
          key: "lagernummergesamt",
          value:
            this.lagernummerzusatz +
            this.lagernummer.toString().padStart(3, "0")
        });
        this.$store.commit("bestellungConfig", {
          key: "store",
          value: this.filialeabholung
        });
        this.$store.commit("bestellungConfig", {
          key: "store",
          value: this.filialeabholung
        });
        this.$store.commit("bestellungConfig", {
          key: "datum",
          value: new Date(this.datumabholung)
        });
        this.groupChange();
        this.$store.commit("bestellungConfig", {
          key: "rendermode",
          value: "editorder"
        });
        this.$store.commit("bestellungConfig", {
          key: "quicksave",
          value: null
        });

        this.dirty = false;
      }
    }
  },
  render() {
    const closeDialog = (
      <VDialog
        ref="closedialog"
        v-model={this.closemodal}
        persistent
        width="400"
      >
        <VCard class="pa-5">
          <VRow no-gutters>
            <VCol align="center">
              <h4>{this.$t("dialog.dirty")}</h4>
            </VCol>
          </VRow>
          <VRow no-gutters class="mt-10">
            <VCol cols="4">
              <VBtn
                color="red"
                dark
                block
                onClick={() => (this.closemodal = false)}
              >
                <VIcon>mdi-close-thick</VIcon>
              </VBtn>
            </VCol>
            <VCol cols="4"></VCol>
            <VCol cols="4">
              <VBtn
                color="green"
                dark
                block
                onClick={() => this.closeEditor(true)}
              >
                <VIcon>mdi-check-bold</VIcon>
              </VBtn>
            </VCol>
          </VRow>
        </VCard>
      </VDialog>
    );

    const deleteDialog = (
      <VDialog
        ref="deletedialog"
        v-model={this.deletemodal}
        persistent
        width="400"
      >
        <VCard class="pa-5">
          <VRow no-gutters>
            <VCol align="center">
              <h4>{this.$t("dialog.delete")}</h4>
            </VCol>
          </VRow>
          <VRow no-gutters class="mt-10">
            <VCol cols="4">
              <VBtn
                color="red"
                dark
                block
                onClick={() => (this.deletemodal = false)}
              >
                <VIcon>mdi-close-thick</VIcon>
              </VBtn>
            </VCol>
            <VCol cols="4"></VCol>
            <VCol cols="4">
              <VBtn color="green" dark block onClick={() => this.delete()}>
                <VIcon>mdi-check-bold</VIcon>
              </VBtn>
            </VCol>
          </VRow>
        </VCard>
      </VDialog>
    );
    const customerOrders = this.existingOrders.map(item => {
      return (
        <VRow
          onClick={() => this.goItem(item.id, item.typ)}
          no-gutters
          class={
            item.id == this.$store.state.bestellungConfig.currentid
              ? "pa-3 currentRow dirty" + this.dirty
              : "pa-3 dirty" + this.dirty
          }
        >
          <VCol cols="2">
            {new Date(item.datumabholung).toLocaleTimeString(
              this.$route.params.lang,
              this.optionsTime
            )}
          </VCol>
          <VCol cols="3">{Filialen.find(item.filialeabholung).name}</VCol>
          <VCol cols="3">
            {item.typ == "ware"
              ? Wareneinheiten.find(item.wareneinheit)
                ? Wareneinheiten.find(item.wareneinheit).bezeichnung
                : ""
              : Fische.find(item.fisch)
              ? Fische.find(item.fisch)["name_" + this.$route.params.lang]
              : ""}
          </VCol>
          <VCol cols="1">{item.anzahl}</VCol>
          <VCol cols="3" align="right">
            {item.gewichtbis > 0
              ? Intl.NumberFormat("de-DE").format(item.gewicht) +
                "g - " +
                Intl.NumberFormat("de-DE").format(item.gewichtbis) +
                "g"
              : item.gewicht > 0
              ? Intl.NumberFormat("de-DE").format(item.gewicht) + "+g"
              : ""}
          </VCol>
        </VRow>
      );
    });
    const timeButtons = this.timeArray.map(item => {
      return (
        <VBtn
          dark={item == this.jsTime}
          depressed
          outlined={item != this.jsTime}
          color="#999"
          class="ma-1 mx-2 timeButton"
          onClick={() => (this.jsTime = item)}
          height="45"
        >
          {item}
        </VBtn>
      );
    });
    const fischKZButtons =
      this.typ == "fisch" && this.fisch && this.fischKZGefiltert
        ? this.fischKZGefiltert.map(item => {
            return (
              <VBtn
                dark={this.fischKZSelected.includes(item.id)}
                color={
                  this.fischKZSelected.includes(item.id)
                    ? "rgba(90,160,190,.8)"
                    : "white"
                }
                small
                class="ma-1 pa-4"
                onClick={() => this.kzSelect(item.id)}
              >
                {item.bezeichnung}
              </VBtn>
            );
          })
        : "";

    return (
      <VCard
        color="rgba(255,255,255,.9)"
        width="100%"
        elevation="0"
        class="fill-height ma-0 pa-0"
        ripple={false}
      >
        {this.customerChoose && (
          <VCard class="floatingCard">
            <VRow no-gutters class="heading">
              <VCol cols="2" class="pa-2">
                <VBtn fab small>
                  <VIcon color="grey">mdi-card-account-details</VIcon>
                </VBtn>
              </VCol>
              <VCol cols="10" class="pa-2 d-flex justify-end">
                <span class="white--text text-h6 ma-1">
                  {this.$t("header.choosecustomer")}
                </span>
              </VCol>
            </VRow>
            <VRow no-gutters class="mt-0 pa-1">
              <VCol>
                <KundenAuswahl
                  on-customer-chosen={this.customerChosen}
                  on-customer-edit={this.customerEdit}
                />
              </VCol>
            </VRow>
            <VRow class="mt-2 bottomRow" no-gutters>
              <VCol cols="3" class="pa-1">
                <VBtn
                  width="90%"
                  large
                  color="blue"
                  dark
                  onClick={() => this.newCustomer()}
                >
                  <VIcon large>mdi-account-plus</VIcon>
                </VBtn>
              </VCol>
              <VCol cols="3" class="pa-1">
                <VBtn
                  width="90%"
                  large
                  color="red"
                  dark
                  onClick={() => (this.customerChoose = false)}
                >
                  <VIcon large>mdi-close-thick</VIcon>
                </VBtn>
              </VCol>
              <VCol cols="6" class="pa-1"></VCol>
            </VRow>
          </VCard>
        )}
        {this.fishChoose && (
          <VCard class="floatingCard">
            <VRow no-gutters class="heading">
              <VCol cols="2" class="pa-2">
                <VBtn fab small>
                  <VIcon color="grey">mdi-fish</VIcon>
                </VBtn>
              </VCol>
              <VCol cols="10" class="pa-2 d-flex justify-end">
                <span class="white--text text-h6 ma-1">
                  {this.$t("header.choosefish")}
                </span>
              </VCol>
            </VRow>
            <VRow no-gutters class="mt-0 pa-1">
              <VCol>
                <FischAuswahl on-fish-chosen={this.fishChosen} />
              </VCol>
            </VRow>
            <VRow class="mt-2 bottomRow" no-gutters>
              <VCol cols="3" class="pa-1">
                <VBtn
                  width="90%"
                  large
                  color="red"
                  dark
                  onClick={() => ((this.fishChoose = false), this.cancelEdit())}
                >
                  <VIcon large>mdi-close-thick</VIcon>
                </VBtn>
              </VCol>
              <VCol cols="9" class="pa-1"></VCol>
            </VRow>
          </VCard>
        )}
        {this.goodsChoose && (
          <VCard class="floatingCard">
            <VRow no-gutters class="heading">
              <VCol cols="2" class="pa-2">
                <VBtn fab small>
                  <VIcon color="grey">mdi-package-variant-closed</VIcon>
                </VBtn>
              </VCol>
              <VCol cols="10" class="pa-2 d-flex justify-end">
                <span class="white--text text-h6 ma-1">
                  {this.$t("header.choosegoods")}
                </span>
              </VCol>
            </VRow>
            <VRow no-gutters class="mt-0 pa-1">
              <VCol>
                <WareAuswahl on-goods-chosen={this.goodsChosen} />
              </VCol>
            </VRow>
            <VRow class="mt-2 bottomRow" no-gutters>
              <VCol cols="3" class="pa-1">
                <VBtn
                  width="90%"
                  large
                  color="red"
                  dark
                  onClick={() => (
                    (this.goodsChoose = false), this.cancelEdit()
                  )}
                >
                  <VIcon large>mdi-close-thick</VIcon>
                </VBtn>
              </VCol>
              <VCol cols="9" class="pa-1"></VCol>
            </VRow>
          </VCard>
        )}
        <VRow no-gutters class="heading">
          <VCol cols="2" class="pa-2">
            <VBtn fab small>
              <VIcon color="grey">mdi-cart-outline</VIcon>
            </VBtn>
          </VCol>
          <VCol cols="10" class="pa-2 d-flex justify-end">
            <span class="white--text text-h6 ma-1">
              {this.$t("header.orderdetails") +
                " " +
                this.$store.state.bestellungConfig.lagernummergesamt}
            </span>
          </VCol>
        </VRow>
        <div
          class={!this.dirty ? "formBorder" : "formBorder red"}
          onClick={() => (this.dirty = true)}
        >
          <VForm ref="form" lazy-validation>
            <VRow no-gutters class="bgFisch">
              <VCol cols="3">
                {this.$store.state.bestellungConfig.ordermode == "fish" && (
                  <VTextField
                    hide-details
                    class="ma-2 whiteBack"
                    dense
                    outlined
                    readonly
                    value={this.derfisch}
                    label={this.$t("label.whichfish")}
                    onClick={() => (this.fishChoose = true)}
                    rules={[this.rules.required]}
                  ></VTextField>
                )}
                {this.$store.state.bestellungConfig.ordermode == "goods" && (
                  <VTextField
                    hide-details
                    class="ma-2 whiteBack"
                    dense
                    outlined
                    readonly
                    value={this.dieware}
                    label={this.$t("label.whichconsumable")}
                    onClick={() => (this.goodsChoose = true)}
                    rules={[this.rules.required]}
                  ></VTextField>
                )}
              </VCol>
              <VCol cols="3">
                <VTextField
                  hide-details
                  class="ma-2 whiteBack"
                  dense
                  outlined
                  type="number"
                  v-model={this.anzahl}
                  label={this.$t("label.pieces")}
                  onFocus={e => e.target.select()}
                  rules={[this.rules.required]}
                ></VTextField>
              </VCol>
              <VCol cols="3">
                {this.nachgewicht && (
                  <VTextField
                    hide-details
                    class="ma-2 whiteBack"
                    dense
                    outlined
                    type="number"
                    min="0"
                    v-model={this.gewicht}
                    label={this.$t("label.weightfrom")}
                    onFocus={e => e.target.select()}
                    onChange={() =>
                      (this.gewicht = this.gewicht == "" ? 0 : this.gewicht)
                    }
                  ></VTextField>
                )}
              </VCol>
              <VCol cols="3">
                {this.nachgewicht && (
                  <VTextField
                    hide-details
                    class="ma-2 whiteBack"
                    dense
                    outlined
                    type="number"
                    v-model={this.gewichtbis}
                    label={this.$t("label.weightto")}
                    onFocus={e => e.target.select()}
                    onChange={() =>
                      (this.gewichtbis =
                        this.gewichtbis == "" ? 0 : this.gewichtbis)
                    }
                  ></VTextField>
                )}
              </VCol>
            </VRow>
            <VRow no-gutters>
              <VCol cols="3" class="bgKunde">
                <VTextField
                  hide-details
                  class="ma-2 whiteBack"
                  dense
                  outlined
                  readonly
                  value={this.derkunde}
                  label={this.$t("label.customer")}
                  onClick={() => (this.customerChoose = true)}
                  rules={[this.rules.required]}
                ></VTextField>
                <VBtn
                  class="ma-2 mt-1"
                  width="90"
                  height="40"
                  small
                  color="white"
                  onClick={() => this.toggleHauptNeben()}
                >
                  {this.HNLabel}
                </VBtn>
                <VTextField
                  hide-details
                  class="ma-2 smallerText80 whiteBack"
                  dense
                  outlined
                  v-model={this.anzahlpersonen}
                  label={this.$t("label.numberpersons")}
                  onFocus={e => e.target.select()}
                ></VTextField>
                <VTextarea
                  hide-details
                  class="ma-2 smallerText whiteBack"
                  dense
                  outlined
                  rows="3"
                  v-model={this.anmerkung}
                  label={this.$t("label.remark")}
                  onFocus={e => e.target.select()}
                ></VTextarea>
              </VCol>
              <VCol cols="3" class="bgKunde">
                <VTextField
                  hide-details
                  class="ma-2 whiteBack"
                  dense
                  outlined
                  readonly
                  value={this.displayDate}
                  label={
                    this.$t("label.date") + " / " + this.$t("label.collection")
                  }
                  onClick={() => (this.modal = true)}
                  rules={[this.rules.datecheck]}
                ></VTextField>
                {deleteDialog}
                {closeDialog}
                <VDialog
                  ref="dialog"
                  v-model={this.modal}
                  persistent
                  width="580"
                >
                  <VRow no-gutters class="whiteBack">
                    <VCol cols="6">
                      <VDatePicker
                        v-model={this.jsDatum}
                        scrollable
                        min={new Date().toISOString().split("T")[0]}
                      >
                        <VBtn
                          width="270px"
                          class="mt-2 mr-2"
                          outlined
                          text
                          color="primary"
                          onClick={() => (this.modal = false)}
                        >
                          <VIcon color="red">mdi-close-thick</VIcon>
                        </VBtn>
                      </VDatePicker>
                    </VCol>
                    <VCol cols="6">
                      <VCard height="386" fluid class="ma-0 pa-0" elevation="0">
                        <VCardTitle dark class="timeHeader justify-center">
                          {this.jsTime}
                        </VCardTitle>
                        {timeButtons}
                      </VCard>
                      <VBtn
                        width="270px"
                        class="mt-2 ml-2"
                        outlined
                        text
                        color="primary"
                        onClick={() => this.dateSave()}
                      >
                        <VIcon color="green">mdi-check-bold</VIcon>
                      </VBtn>
                    </VCol>
                  </VRow>
                </VDialog>
                <VSelect
                  hide-details
                  class="ma-2 whiteBack"
                  dense
                  outlined
                  items={this.filialen}
                  v-model={this.filialeabholung}
                  label={
                    this.$t("label.store") + " / " + this.$t("label.collection")
                  }
                  rules={[this.rules.required]}
                  onChange={() => {
                    this.$store.commit("bestellungConfig", {
                      key: "storecollection",
                      value: this.filialeabholung
                    });
                    if (this.lagernummer) {
                      this.lagerNummerReset = true;
                    }
                    this.lagernummer = null;
                  }}
                ></VSelect>
                <div class="pa-2 pt-0">
                  <VBtn
                    dark={this.rueckruf === true || this.rueckruf === 1}
                    block
                    color={this.rueckruf ? "rgba(90, 160, 190, 0.8)" : "white"}
                    onClick={() => this.alterWithCheck("rueckruf", false)}
                  >
                    <VIcon class="mr-2">
                      {this.rueckruf ? "mdi-check" : "mdi-close"}
                    </VIcon>
                    <span class="buttonTextWithIcon">
                      {this.rueckruf
                        ? this.$t("label.callback")
                        : this.$t("label.nocallback")}
                    </span>
                  </VBtn>
                  <VBtn
                    class="mt-2"
                    dark={this.zustellung === true || this.zustellung === 1}
                    block
                    color={
                      this.zustellung ? "rgba(90, 160, 190, 0.8)" : "white"
                    }
                    onClick={() => this.alterWithCheck("zustellung", false)}
                  >
                    <VIcon class="mr-2">
                      {this.zustellung ? "mdi-check" : "mdi-close"}
                    </VIcon>
                    <span class="buttonTextWithIcon">
                      {this.zustellung
                        ? this.$t("label.delivery")
                        : this.$t("label.nodelivery")}
                    </span>
                  </VBtn>
                </div>
              </VCol>
              <VCol cols="6" class="pa-1 bgFisch">
                {fischKZButtons}
              </VCol>
            </VRow>
          </VForm>
        </div>
        <VCard
          height="calc(100vh - 412px)"
          max-height="calc(100vh - 412px)"
          class="mt-2"
        >
          <div class="overviewCardHead font-weight-bold pa-2">
            {this.$t("header.existingorders")}
          </div>
          <div class="overviewCardBody">
            <VRow no-gutters>
              <VCol cols="2">{this.$t("label.time")}</VCol>
              <VCol cols="3">{this.$t("label.store")}</VCol>
              <VCol cols="3">{this.$t("label.whichfish")}</VCol>
              <VCol cols="1">{this.$t("label.pieces")}</VCol>
              <VCol cols="3" align="right">
                {this.$t("label.weight")}
              </VCol>
            </VRow>

            {customerOrders}
          </div>
        </VCard>
        <VRow class="mt-2 bottomRow" no-gutters>
          <VCol cols="3" class="pa-1" align="left">
            <VBtn
              class="ma-1"
              width="90%"
              large
              color="red"
              dark
              onClick={() => this.closeEditor()}
            >
              <VIcon class="mr-2">mdi-arrow-left-thick</VIcon>
              <VIcon large>mdi-view-list</VIcon>
            </VBtn>
          </VCol>
          <VCol cols="3" class="pa-1" align="center">
            {!this.dirty &&
              this.$store.state.bestellungConfig.rendermode == "editorder" && (
                <VBtn
                  width="90%"
                  large
                  color="orange"
                  dark
                  class="ma-1"
                  onClick={() => (this.deletemodal = true)}
                >
                  <VIcon large>mdi-delete</VIcon>
                </VBtn>
              )}
          </VCol>
          <VCol cols="3" class="pa-1" align="center">
            {!this.dirty &&
              this.$store.state.bestellungConfig.rendermode == "editorder" && (
                <VBtn
                  width="90%"
                  large
                  color="#008B8B"
                  dark
                  class="ma-1"
                  onClick={() => this.addItem("goods")}
                >
                  <VIcon large>mdi-package-variant-closed</VIcon>
                  <VIcon class="ml-2">mdi-plus-thick</VIcon>
                </VBtn>
              )}
            {this.dirty &&
              (this.$store.state.bestellungConfig.rendermode == "editorder" ||
                this.$store.state.bestellungConfig.rendermode == "additem") && (
                <VBtn
                  width="90%"
                  large
                  color="red"
                  dark
                  class="ma-1"
                  onClick={() => this.cancelEdit()}
                >
                  <VIcon large>mdi-close-thick</VIcon>
                </VBtn>
              )}
          </VCol>
          <VCol cols="3" class="pa-1" align="right">
            {this.dirty && (
              <VBtn
                width="90%"
                class="ma-1"
                large
                color="green"
                dark
                onClick={this.save}
              >
                <VIcon large>mdi-check-bold</VIcon>
              </VBtn>
            )}
            {!this.dirty &&
              (this.$store.state.bestellungConfig.rendermode == "editorder" ||
                this.$store.state.bestellungConfig.rendermode ==
                  "overview") && (
                <VBtn
                  width="90%"
                  large
                  color="green"
                  dark
                  class="ma-1"
                  onClick={() => this.addItem("fish")}
                >
                  <VIcon large>mdi-fish</VIcon>
                  <VIcon class="ml-2">mdi-plus-thick</VIcon>
                </VBtn>
              )}
          </VCol>
        </VRow>
      </VCard>
    );
  }
};
