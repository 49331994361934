import {
  VApp,
  VAppBar,
  VContainer,
  VImg,
  VSpacer,
  VBtn,
  VMain,
  VIcon,
  VCard
} from "vuetify/lib";
import "./App.scss";
import { initStore } from "@/store/tables";

export default {
  data() {
    return {
      queuedRequests: false,
      users: [],
      langSelectBox: false,
      langItems: [
        { name: "DE", image: "/img/flag-de.png" },
        { name: "EN", image: "/img/flag-en.png" },
        { name: "IT", image: "/img/flag-it.png" },
        { name: "SI", image: "/img/flag-si.png" }
      ]
    };
  },
  methods: {
    goHome() {
      if (this.$route.path != "/" + this.$route.params.lang + "/home") {
        this.$router.push({
          path: "/" + this.$route.params.lang + "/home"
        });
      }
    },
    langSelect() {
      this.langSelectBox = !this.langSelectBox;
    },
    langChoosen(whichLanguage) {
      this.langSelectBox = !this.langSelectBox;
      this.$router.push({
        path: this.$router.currentRoute.fullPath.replace(
          /^\/\D\D\//,
          "/" + whichLanguage + "/"
        )
      });
    }
  },
  created() {
    this.$root.$on("appUpdated", async () => {
      const agree = await this.$root.$confirm(
        "Klicken Sie auf OK, um die Applikation jetzt neu zu starten.",
        {
          icon: false,
          title: "Update installiert"
        }
      );
      if (agree) {
        location.replace(
          `${window.location.protocol}//${window.location.host}`
        );
      }
    });
    this.$root.$on("queueCountUpdated", count => {
      if (count === 0 && this.queuedRequests) {
        initStore();
      }
      this.queuedRequests = count > 0;
    });
  },
  watch: {
    "$store.state.userlevel": {
      immediate: true,
      async handler() {
        await initStore();
      }
    }
  },
  render() {
    return (
      <VApp>
        {this.queuedRequests && <div class="requestWaiting top" />}
        {this.queuedRequests && <div class="requestWaiting bottom" />}
        {!this.$store.state.mobile && (
          <VAppBar app color="rgb(245,245,245)" class="upAbove">
            <div class="d-flex align-left">
              <VImg
                onClick={() => this.goHome()}
                position="left"
                alt="Logo sofisch|system"
                class="align-left"
                contain
                src="/img/sofisch-system-logo.png"
                transition="scale-transition"
                height="50"
                style="cursor: pointer;"
              />
            </div>
            <VSpacer />
            {!this.$store.state.mobile &&
              !["Home", "Login"].includes(this.$router.currentRoute.name) && (
                <VBtn
                  to={{
                    name:
                      this.$router.currentRoute.meta.userlevel > 4
                        ? "Verkauf"
                        : "Administration"
                  }}
                  text
                >
                  <VIcon>mdi-home</VIcon>
                  <span class="ml-2">{this.$t("navigation.dashboard")}</span>
                </VBtn>
              )}
            {this.$store.state.userlevel !== Infinity && (
              <VBtn
                to={{ path: "/" + this.$route.params.lang + "/logout" }}
                text
              >
                <VIcon>mdi-account</VIcon>
                <span class="ml-2">{this.$t("navigation.logout")}</span>
              </VBtn>
            )}
            <div class="langSelectSpace"></div>
            <VCard elevation="1" class="langSelect" color="rgb(245,245,245)">
              {!this.langSelectBox > 0 && (
                <VBtn
                  small
                  elevation="0"
                  class="ma-2"
                  onClick={this.langSelect}
                >
                  <VImg
                    width="20"
                    height="20"
                    class="langImage"
                    src={"/img/flag-" + this.$i18n.locale + ".png"}
                  ></VImg>
                  <span class="langKey">{this.$i18n.locale}</span>
                </VBtn>
              )}
              {this.langSelectBox > 0 && (
                <div>
                  <VBtn
                    class="ma-2"
                    small
                    elevation="0"
                    onClick={this.langChoosen.bind(this, "de")}
                  >
                    <VImg
                      width="20"
                      height="20"
                      class="langImage"
                      src="/img/flag-de.png"
                    ></VImg>
                    <span class="langKey">DE</span>
                  </VBtn>
                  <VBtn
                    small
                    elevation="0"
                    class="ma-2"
                    onClick={this.langChoosen.bind(this, "en")}
                  >
                    <VImg
                      width="20"
                      height="20"
                      class="langImage"
                      src="/img/flag-en.png"
                    ></VImg>
                    <span class="langKey">EN</span>
                  </VBtn>
                  <VBtn
                    small
                    elevation="0"
                    class="ma-2"
                    onClick={this.langChoosen.bind(this, "it")}
                  >
                    <VImg
                      width="20"
                      height="20"
                      class="langImage"
                      src="/img/flag-it.png"
                    ></VImg>
                    <span class="langKey">IT</span>
                  </VBtn>
                  <VBtn
                    small
                    elevation="0"
                    class="ma-2"
                    onClick={this.langChoosen.bind(this, "si")}
                  >
                    <VImg
                      width="20"
                      height="20"
                      class="langImage"
                      src="/img/flag-si.png"
                    ></VImg>
                    <span class="langKey">SI</span>
                  </VBtn>
                  <VBtn
                    small
                    onClick={this.langSelect}
                    class="ma-2"
                    width="75"
                    elevation="1"
                  >
                    <VIcon small>mdi-close</VIcon>
                  </VBtn>
                </div>
              )}
            </VCard>
          </VAppBar>
        )}

        <VMain class="main">
          <VContainer
            fluid
            class={
              !["Home", "Login"].includes(this.$router.currentRoute.name) &&
              this.$store.state.mobile
                ? "fill-height pa-0 ma-0"
                : ""
            }
          >
            <RouterView />
          </VContainer>
        </VMain>
      </VApp>
    );
  }
};
