import { Model } from "@vuex-orm/core";

export default class Fisch extends Model {
  static entity = "fisch";
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      kategorie: this.string("").nullable(),
      kennzeichnung: this.string("").nullable(),
      gruppe: this.string("").nullable(),
      name_de: this.string("").nullable(),
      name_lat: this.string("").nullable(),
      name_en: this.string("").nullable(),
      name_fr: this.string("").nullable(),
      name_it: this.string("").nullable(),
      name_sp: this.string("").nullable(),
      name_si: this.string("").nullable(),
      name_hr: this.string("").nullable(),
      name_ru: this.string("").nullable(),
      nachgewicht: this.boolean(true),
      foto: this.string("").nullable(),
      anmerkung: this.string("").nullable(),
      plu: this.string("").nullable()
    };
  }
}
