import { Model } from "@vuex-orm/core";

export default class Fischkennzeichnungen extends Model {
  static entity = "fischkennzeichnung";
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      kategorie: this.string("").nullable(),
      kennzeichnung: this.string("").nullable(),
      gruppe: this.string("").nullable(),
      bezeichnung: this.string("").nullable(),
      exklusiv: this.boolean(false),
      anmerkung: this.string("").nullable()
    };
  }
}
