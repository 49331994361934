import { Model } from "@vuex-orm/core";

export default class Bestellungen extends Model {
  static entity = "bestellung";
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      filiale: this.string("").nullable(),
      datum: this.attr(undefined),
      filialeabholung: this.string("").nullable(),
      datumabholung: this.attr(undefined),
      mitarbeiter: this.string("").nullable(),
      typ: this.string("").nullable(),
      fischeinheit: this.string("").nullable(),
      fisch: this.string("").nullable(),
      wareneinheit: this.string("").nullable(),
      produkt: this.string("").nullable(),
      kennzeichnungen: this.string("").nullable(),
      bezahlung: this.string("").nullable(),
      anzahl: this.number(1),
      anzahlpersonen: this.string("").nullable(),
      hauptneben: this.string("").nullable(),
      gewicht: this.number(0).nullable(),
      gewichtbis: this.number(0).nullable(),
      kunde: this.string("").nullable(),
      kundedirekt: this.string("").nullable(),
      kundekontakt: this.string("").nullable(),
      rueckruf: this.number(0),
      zustellung: this.number(0),
      status: this.string("").nullable(),
      bestellnummer: this.string("").nullable(),
      bestellnummerkurz: this.string("").nullable(),
      lagernummer: this.number(0),
      lagernummerzusatz: this.string("").nullable(),
      anmerkung: this.string("").nullable()
    };
  }
}
