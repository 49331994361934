import { Model } from "@vuex-orm/core";

export default class Fischeinheiten extends Model {
  static entity = "fischeinheit";
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      kategorie: this.string("").nullable(),
      kennzeichnung: this.string("").nullable(),
      fisch: this.string(null),
      fischkennzeichnungen: this.string("").nullable(),
      min_gewicht: this.number(0),
      max_gewicht: this.number(0),
      faktor_filet: this.number(0.5),
      faktor_rein: this.number(0.9),
      stuecke: this.number(1),
      bezeichnung: this.string("").nullable(),
      anmerkung: this.string("").nullable(),
      gruppe: this.string("").nullable(),
      plu: this.string("").nullable()
    };
  }
}
