import { VDataTable, VTextField, VImg } from "vuetify/lib";
import goTo from "vuetify/lib/services/goto";
import Wareneinheiten from "@/models/wareneinheiten";
import Verbrauchswaren from "@/models/verbrauchswaren";

//import { v4 as uuidv4 } from "uuid";
import "../../overviewTables.scss";
import {
  updateSortValue,
  updateSortValuesFromQuery,
  highlight
} from "@/utils/helpers";

export default {
  props: ["target"],
  data() {
    return {
      search: "",
      sortBy: undefined,
      sortDesc: false,
      waren: null
    };
  },
  created() {
    this.waren = Wareneinheiten.query()
      .where(
        good =>
          good.verbrauchsware &&
          good.verbrauchsware != "" &&
          good.verbrauchsware != "null"
      )
      .get();
  },
  mounted() {
    updateSortValuesFromQuery(this);
    if (
      this.$store.state.bestellungConfig.consumable &&
      this.$store.state.bestellungConfig.consumable != ""
    ) {
      goTo(".selectedTR", { container: ".v-data-table__wrapper" });
    }
  },
  watch: {
    search(value) {
      this.updateSortValue(this, "search", value);
    }
  },
  beforeDestroy() {},
  computed: {
    headers() {
      return [
        { text: this.$t("label.photo"), value: "foto" },
        {
          text: this.$t("label.name"),
          value: "bezeichnung"
        },
        { text: this.$t("label.group"), value: "gruppe" }
      ];
    }
  },
  methods: {
    itemFoto(id) {
      const VW = Verbrauchswaren.find(id);
      return VW ? VW.foto : "";
    },
    goodsFilter(value, search, item) {
      return item["bezeichnung"].includes(search);
    },
    clearSelected() {
      this.$store.commit("bestellungConfig", {
        key: "consumable",
        value: ""
      });
    },
    searchString(item) {
      this.search += item;
      this.clearSelected();
    },
    clearSearch() {
      this.search = "";
      this.clearSelected();
    },
    goodsChosen(id) {
      this.$store.commit("bestellungConfig", {
        key: "consumable",
        value: id
      });
      this.$emit("goods-chosen");
    },
    highlight,
    updateSortValue
  },
  render() {
    const scopedSlots = {
      item: item => {
        return (
          <tr
            class={
              item.item.id == this.$store.state.bestellungConfig.consumable
                ? "selectedTR"
                : ""
            }
            onClick={this.goodsChosen.bind(this, item.item.id)}
          >
            <td>
              <VImg
                class="ma-1"
                src={
                  this.itemFoto(item.item.verbrauchsware) ||
                  "/img/no-image-placeholder.png"
                }
                height="80"
                width="80"
                contain
              ></VImg>
            </td>
            <td>
              <span
                domPropsInnerHTML={`<span> ${this.highlight(
                  item.item["bezeichnung"],
                  this.search
                )}</span>`}
              ></span>
            </td>
            <td>
              <span
                domPropsInnerHTML={`<span> ${this.highlight(
                  item.item.gruppe,
                  this.search
                )}</span>`}
              ></span>
            </td>
          </tr>
        );
      },
      top: () => {
        return (
          <VTextField
            hide-details
            solo
            append-icon="mdi-magnify"
            v-model={this.search}
            class="customerHeadSearch pa-0 ma-0"
          ></VTextField>
        );
      }
    };
    return (
      <VDataTable
        itemKey="id"
        items={this.waren}
        headers={this.headers}
        search={this.search}
        scopedSlots={scopedSlots}
        disable-pagination
        hide-default-footer
        height="calc(100vh - 125px)"
        sort-by="bezeichnung"
        fixed-header
        sortBy={this.sortBy}
        sortDesc={this.sortDesc}
        on={{
          "update:sort-by": value =>
            this.updateSortValue(this, "sortBy", value),
          "update:sort-desc": value =>
            this.updateSortValue(this, "sortDesc", value)
        }}
      />
    );
  }
};
