//import { VForm, VTextField, VCard, VBtn } from "vuetify/lib";
import LoginMobile from "@/views/LoginMobile";
import LoginDesktop from "@/views/LoginDesktop";
import { VIcon, VBtn } from "vuetify/lib";
import "./mobile/mobileClasses.scss";

export default {
  data() {
    return {
      pinLogin: false
    };
  },
  methods: {},
  render() {
    return (
      <div class="positionRelative">
        <VBtn onClick={() => (this.pinLogin = !this.pinLogin)}>
          <VIcon>mdi-square</VIcon>
          {(this.pinLogin && "Standard Login") || "PIN-Login"}
        </VBtn>
        <VBtn
          class="upperRight"
          to={`/${this.$route.params.lang}/mobile/verkauf`}
        >
          <VIcon>mdi-store</VIcon>
          Verkaufs-Seite
        </VBtn>
        {(this.pinLogin && <LoginMobile />) || <LoginDesktop />}
      </div>
    );
  }
};
