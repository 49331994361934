import { Model } from "@vuex-orm/core";

export default class Verbrauchswaren extends Model {
  static entity = "verbrauchsware";
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      kategorie: this.string("").nullable(),
      kennzeichnung: this.string("").nullable(),
      gruppe: this.string("").nullable(),
      name_de: this.string("").nullable(),
      name_lat: this.string("").nullable(),
      name_en: this.string("").nullable(),
      name_fr: this.string("").nullable(),
      name_it: this.string("").nullable(),
      name_sp: this.string("").nullable(),
      name_si: this.string("").nullable(),
      name_hr: this.string("").nullable(),
      name_ru: this.string("").nullable(),
      nachgewicht: this.boolean(false),
      gewicht: this.number(0),
      foto: this.string("").nullable(),
      anmerkung: this.string("").nullable(),
      plu: this.string("").nullable()
    };
  }
}
