export const userLevels = {
  2: "admin",
  4: "office",
  8: "vendor",
  16: "driver",
  32: "purchase",
  64: "warehouse"
};

export const bestellungStatus = [
  {
    key: "VB",
    text: "preparation",
    icon: "mdi-cart-arrow-down",
    color: "blue",
    class: ""
  },
  {
    key: "NV",
    text: "notavailable",
    icon: "mdi-cart-off",
    color: "red",
    class: ""
  },
  {
    key: "OK",
    text: "ready",
    icon: "mdi-cart",
    color: "teal",
    class: ""
  },
  {
    key: "AH",
    text: "pickedup",
    icon: "mdi-cart-arrow-right",
    color: "light-green",
    class: ""
  },
  {
    key: "ZG",
    text: "delivered",
    icon: "mdi-van-utility",
    color: "lime",
    class: ""
  }
];

export const einkaufStatus = [
  {
    key: "VB",
    text: "preparation",
    icon: "mdi-cart-arrow-down",
    color: "blue",
    class: ""
  },
  {
    key: "OK",
    text: "ready",
    icon: "mdi-cart",
    color: "teal",
    class: ""
  }
];

export const zuchtID = "5c599a35-764d-4862-bc2d-ea16be9546e3";
