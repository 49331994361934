import { io } from "socket.io-client";
import { initStore, loadTable } from "../store/tables";

const socket = () => {
  const socket = io({
    transports: ["polling"]
  });
  socket.on("change:data", msg => {
    const client = document.cookie.match(/client=([0-9a-f-]+)/)?.[1];
    if (msg.client !== client) {
      loadTable(msg.endpoint);
    }
  });
  socket.io.on("reconnect", initStore);
};

export default socket;
