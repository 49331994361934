import { Model } from "@vuex-orm/core";

export default class Lieferanten extends Model {
  static entity = "lieferant";
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      kategorie: this.string("").nullable(),
      kennzeichnung: this.string("").nullable(),
      gruppe: this.string("").nullable(),
      firmenname: this.string("").nullable(),
      anrede: this.string("").nullable(),
      vorname: this.string("").nullable(),
      nachname: this.string("").nullable(),
      adresse: this.string("").nullable(),
      adresse2: this.string("").nullable(),
      plz: this.string("").nullable(),
      ort: this.string("").nullable(),
      land: this.string("").nullable(),
      telefon: this.string("").nullable(),
      mobil: this.string("").nullable(),
      email: this.string("").nullable(),
      geolocation: this.string("").nullable(),
      iban: this.string("").nullable(),
      bic: this.string("").nullable(),
      bankname: this.string("").nullable(),
      inhaber: this.string("").nullable(),
      uid: this.string("").nullable(),
      ust: this.boolean(1),
      ustprozent: this.number(20),
      anmerkung: this.string("").nullable(),
      kundennummer: this.string("").nullable()
    };
  }
}
