import { VSelect } from "vuetify/lib";
import Filialen from "@/models/filialen";

export default {
  data() {
    return {
      pointOfSale: null,
      poslist: []
    };
  },
  computed: {
    filiale: {
      get() {
        return this.$store.state.bestellungConfig.store || "";
      },
      set(value) {
        this.$store.commit("bestellungConfig", { key: "store", value });
      }
    }
  },
  watch: {
    "$store.state.entities.filiale.data": {
      immediate: true,
      handler() {
        this.pointOfSale = Filialen.query().where("verkauf", true).get();
        this.poslist.length = 0;
        this.pointOfSale.map(item => {
          this.poslist.push({ value: item.id, text: item.name });
        });
      }
    }
  },
  render() {
    return (
      <VSelect
        background-color="rgb(0, 113, 113)"
        dark
        items={this.poslist}
        outlined
        dense
        hide-details
        v-model={this.$store.state.bestellungConfig.store}
      ></VSelect>
    );
  }
};
