import Vue from "vue";
import i18n from "./localization/i18n";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import router from "./router";
import store from "./store";
import socket from "./plugins/socket";
import vuetify from "./plugins/vuetify";
import VuetifyConfirm from "vuetify-confirm";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import auth from "./plugins/auth";

Vue.use(socket);

const app = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
});

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: app.$t("button.ok"),
  buttonFalseText: app.$t("button.cancel")
});

auth(app);
registerServiceWorker(app);

app.$mount("#app");
