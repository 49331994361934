import { Model } from "@vuex-orm/core";

export default class Users extends Model {
  static entity = "user";
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      username: this.string(""),
      userlevel: this.number(Infinity),
      vorname: this.string("").nullable(),
      nachname: this.string("").nullable(),
      mobil: this.attr(null).nullable(),
      email: this.attr(null).nullable(),
      foto: this.string("").nullable(),
      anmerkung: this.string("").nullable(),
      filiale: this.string("").nullable()
    };
  }
}
