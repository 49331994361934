import { Model } from "@vuex-orm/core";

export default class Fanggebiete extends Model {
  static entity = "fanggebiet";
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      name_de: this.string("").nullable(),
      name_lat: this.string("").nullable(),
      name_en: this.string("").nullable(),
      name_fr: this.string("").nullable(),
      name_it: this.string("").nullable(),
      name_sp: this.string("").nullable(),
      name_si: this.string("").nullable(),
      name_hr: this.string("").nullable(),
      name_ru: this.string("").nullable(),
      laender: this.string("").nullable(),
      kuerzel: this.string("").nullable(),
      normcode: this.string("").nullable(),
      anmerkung: this.string("").nullable()
    };
  }
}
