import { Model } from "@vuex-orm/core";

export default class Filialen extends Model {
  static entity = "filiale";
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      kategorie: this.string("").nullable(),
      kennzeichnung: this.string("").nullable(),
      gruppe: this.string("").nullable(),
      name: this.string("").nullable(),
      adresse: this.string("").nullable(),
      adresse2: this.string("").nullable(),
      plz: this.string("").nullable(),
      ort: this.string("").nullable(),
      land: this.string("").nullable(),
      telefon: this.string("").nullable(),
      mobil: this.string("").nullable(),
      email: this.string("").nullable(),
      lager: this.string("").nullable(),
      ansprechpartner: this.string("").nullable(),
      iban: this.string("").nullable(),
      bic: this.string("").nullable(),
      bankname: this.string("").nullable(),
      anmerkung: this.string("").nullable(),
      verkauf: this.boolean(0),
      kuerzel: this.string("").nullable()
    };
  }
}
