export default {
  data() {
    return {
      users: []
    };
  },
  mounted() {},
  render() {
    return (
      <div>
        <h1>Einkauf</h1>
      </div>
    );
  }
};
