import { Model } from "@vuex-orm/core";

export default class Wareneinheiten extends Model {
  static entity = "wareneinheit";
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      kategorie: this.string("").nullable(),
      kennzeichnung: this.string("").nullable(),
      verbrauchsware: this.string(null),
      einheiten: this.number(0),
      bezeichnung: this.string("").nullable(),
      anmerkung: this.string("").nullable(),
      gruppe: this.string("").nullable(),
      intern: this.boolean(false).nullable(),
      plu: this.string("").nullable()
    };
  }
}
