import Home from "../views/Home";
import mobileHome from "../views/mobile/mobileHome";
import Administration from "../views/Administration";
import Login from "../views/Login";
import System from "../views/System";
import store from "@/store";

import axios from "axios";

import Verkauf from "../views/mobile/verkauf";
import KundenEdit from "../views/mobile/kundenEdit";
import BestellungenEdit from "../views/mobile/bestellungenEdit";
import Einkauf from "../views/mobile/einkauf";
import About from "../views/About";

export const routes = [
  {
    path: "/:lang/home",
    name: "Home",
    component: Home,
    meta: {
      title: "Sofisch Home",
      userlevel: 4 // enforce at least "office"
    }
  },
  {
    path: "/:lang/administration",
    name: "Administration",
    component: Administration,
    meta: {
      title: "Sofisch Administration",
      userlevel: 3
    }
  },
  {
    path: "/",
    redirect: "/de/home"
  },
  {
    path: "/login",
    redirect: "/de/login"
  },
  {
    path: "/:lang/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
      userlevel: Infinity
    }
  },
  {
    path: "/:lang/logout",
    name: "logout",
    component: {
      async beforeRouteEnter(to, from, next) {
        try {
          await axios.get("/api/logout");
          localStorage.removeItem("sofisch");
          store.dispatch("userlevel", Infinity);
          store.dispatch("username", undefined);
          location.replace("./"); // completely reloads the app
          //next("/de/login"); // this works fine?
        } catch (e) {
          next();
        }
      }
    }
  },
  {
    path: "/:lang/mobile",
    name: "Mobile",
    component: mobileHome,
    meta: {
      title: "Sofisch Mobile",
      userlevel: 8
    }
  },
  {
    path: "/:lang/mobile/verkauf",
    name: "Verkauf",
    component: Verkauf,
    meta: {
      title: "Sofisch Verkauf",
      userlevel: 8
    }
  },
  {
    path: "/:lang/mobile/kunden/:id",
    name: "kundenEditMobil",
    component: KundenEdit,
    meta: {
      title: "Sofisch Verkauf - Kundendatenbank",
      userlevel: 8
    }
  },
  {
    path: "/:lang/mobile/bestellungen/:id",
    name: "bestellungenEditMobil",
    component: BestellungenEdit,
    meta: {
      title: "Sofisch Verkauf - Bestellung aufnehmen oder bearbeiten",
      userlevel: 8
    }
  },
  {
    path: "/:lang/mobile/einkauf",
    name: "Einkauf",
    component: Einkauf,
    meta: {
      title: "Sofisch Einkauf",
      userlevel: 32
    }
  },
  {
    path: "/:lang/about",
    name: "About",
    component: About,
    meta: {
      title: "About"
    }
  },
  {
    path: "/:lang/system",
    name: "system",
    component: System,
    meta: {
      title: "system",
      userlevel: 2
    }
  },
  {
    path: "/:lang/system/benutzer",
    name: "benutzer",
    component: () =>
      import(/* webpackChunkName: "benutzer" */ "../views/system/benutzer"),
    meta: {
      title: "benutzer",
      userlevel: 2
    }
  },
  {
    path: "/:lang/system/benutzer/:id",
    name: "benutzerEdit",
    component: () =>
      import(
        /* webpackChunkName: "benutzerEdit" */ "../views/system/benutzerEdit"
      ),
    meta: {
      title: "benutzerEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/system/mitarbeiter",
    name: "mitarbeiter",
    component: () =>
      import(
        /* webpackChunkName: "mitarbeiter" */ "../views/system/mitarbeiter"
      ),
    meta: {
      title: "mitarbeiter",
      userlevel: 2
    }
  },
  {
    path: "/:lang/system/mitarbeiter/:id",
    name: "mitarbeiterEdit",
    component: () =>
      import(
        /* webpackChunkName: "mitarbeiterEdit" */ "../views/system/mitarbeiterEdit"
      ),
    meta: {
      title: "mitarbeiterEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/system/registrierkasse",
    name: "registrierkasse",
    component: () =>
      import(
        /* webpackChunkName: "registrierkasse" */ "../views/system/registrierkasse"
      ),
    meta: {
      title: "registrierkasse",
      userlevel: 3
    }
  },
  {
    path: "/:lang/system/artikel",
    name: "artikel",
    component: () =>
      import(/* webpackChunkName: "artikel" */ "../views/system/artikel"),
    meta: {
      title: "artikel",
      userlevel: 3
    }
  },
  {
    path: "/:lang/stammdaten",
    name: "stammdaten",
    component: () =>
      import(/* webpackChunkName: "stammdaten" */ "../views/stammdaten"),
    meta: {
      title: "stammdaten",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/fanggebiete",
    name: "fanggebiete",
    component: () =>
      import(
        /* webpackChunkName: "fanggebiete" */ "../views/stammdaten/fanggebiete"
      ),
    meta: {
      title: "fanggebiete",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/fanggebiete/:id",
    name: "fanggebieteEdit",
    component: () =>
      import(
        /* webpackChunkName: "fanggebieteEdit" */ "../views/stammdaten/fanggebieteEdit"
      ),
    meta: {
      title: "fanggebieteEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/fangmethoden",
    name: "fangmethoden",
    component: () =>
      import(
        /* webpackChunkName: "stammdatenFangmethoden" */ "../views/stammdaten/fangmethoden"
      ),
    meta: {
      title: "fangmethoden",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/fangmethoden/:id",
    name: "fangmethodenEdit",
    component: () =>
      import(
        /* webpackChunkName: "fangmethodenEdit" */ "../views/stammdaten/fangmethodenEdit"
      ),
    meta: {
      title: "fangmethodenEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/filialen",
    name: "filialen",
    component: () =>
      import(/* webpackChunkName: "filialen" */ "../views/stammdaten/filialen"),
    meta: {
      title: "filialen",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/filialen/:id",
    name: "filialenEdit",
    component: () =>
      import(
        /* webpackChunkName: "filialenEdit" */ "../views/stammdaten/filialenEdit"
      ),
    meta: {
      title: "filialenEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/fische",
    name: "fische",
    component: () =>
      import(/* webpackChunkName: "fische" */ "../views/stammdaten/fische"),
    meta: {
      title: "fische",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/fische/:id",
    name: "fischeEdit",
    component: () =>
      import(
        /* webpackChunkName: "fischeEdit" */ "../views/stammdaten/fischeEdit"
      ),
    meta: {
      title: "fischeEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/kategorien",
    name: "kategorien",
    component: () =>
      import(
        /* webpackChunkName: "kategorien" */ "../views/stammdaten/kategorien"
      ),
    meta: {
      title: "kategorien",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/kategorien/:id",
    name: "kategorienEdit",
    component: () =>
      import(
        /* webpackChunkName: "kategorienEdit" */ "../views/stammdaten/kategorienEdit"
      ),
    meta: {
      title: "kategorienEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/sortiment/fischeinheiten",
    name: "fischeinheiten",
    component: () =>
      import(
        /* webpackChunkName: "fischeinheiten" */ "../views/sortiment/fischeinheiten"
      ),
    meta: {
      title: "fischeinheiten",
      userlevel: 2
    }
  },
  {
    path: "/:lang/sortiment/fischeinheiten/:id",
    name: "fischeinheitenEdit",
    component: () =>
      import(
        /* webpackChunkName: "fischeinheitenEdit" */ "../views/sortiment/fischeinheitenEdit"
      ),
    meta: {
      title: "fischeinheitenEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/fischkennzeichnungen",
    name: "fischkennzeichnungen",
    component: () =>
      import(
        /* webpackChunkName: "fischkennzeichnung" */ "../views/stammdaten/fischkennzeichnungen"
      ),
    meta: {
      title: "fischkennzeichnungen",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/fischkennzeichnungen/:id",
    name: "fischkennzeichnungenEdit",
    component: () =>
      import(
        /* webpackChunkName: "fischkennzeichnungenEdit" */ "../views/stammdaten/fischkennzeichnungenEdit"
      ),
    meta: {
      title: "fischkennzeichnungenEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/kennzeichnungen",
    name: "kennzeichnung",
    component: () =>
      import(
        /* webpackChunkName: "kennzeichnung" */ "../views/stammdaten/kennzeichnung"
      ),
    meta: {
      title: "kennzeichnung",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/kennzeichnungen/:id",
    name: "kennzeichnungEdit",
    component: () =>
      import(
        /* webpackChunkName: "kennzeichnungEdit" */ "../views/stammdaten/kennzeichnungEdit"
      ),
    meta: {
      title: "kennzeichnungEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/kunden",
    name: "kunden",
    component: () =>
      import(/* webpackChunkName: "kunden" */ "../views/stammdaten/kunden"),
    meta: {
      title: "kunden",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/kunden/:id",
    name: "kundenEdit",
    component: () =>
      import(
        /* webpackChunkName: "kundenEdit" */ "../views/stammdaten/kundenEdit"
      ),
    meta: {
      title: "kundenEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/lieferanten",
    name: "lieferanten",
    component: () =>
      import(
        /* webpackChunkName: "lieferanten" */ "../views/stammdaten/lieferanten"
      ),
    meta: {
      title: "lieferanten",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/lieferanten/:id",
    name: "lieferantenEdit",
    component: () =>
      import(
        /* webpackChunkName: "lieferantenEdit" */ "../views/stammdaten/lieferantenEdit"
      ),
    meta: {
      title: "lieferantenEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/sortiment",
    name: "sortiment",
    component: () =>
      import(/* webpackChunkName: "sortiment" */ "../views/sortiment"),
    meta: {
      title: "sortiment",
      userlevel: 2
    }
  },
  {
    path: "/:lang/sortiment/produkte",
    name: "produkte",
    component: () =>
      import(/* webpackChunkName: "produkte" */ "../views/sortiment/produkte"),
    meta: {
      title: "produkte",
      userlevel: 2
    }
  },
  {
    path: "/:lang/sortiment/produkte/:id",
    name: "produkteEdit",
    component: () =>
      import(
        /* webpackChunkName: "produkteEdit" */ "../views/sortiment/produkteEdit"
      ),
    meta: {
      title: "produkteEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/verbrauchswaren",
    name: "verbrauchswaren",
    component: () =>
      import(
        /* webpackChunkName: "verbrauchswaren" */ "../views/stammdaten/verbrauchswaren"
      ),
    meta: {
      title: "verbrauchswaren",
      userlevel: 2
    }
  },
  {
    path: "/:lang/stammdaten/verbrauchswaren/:id",
    name: "verbrauchswarenEdit",
    component: () =>
      import(
        /* webpackChunkName: "verbrauchswarenEdit" */ "../views/stammdaten/verbrauchswarenEdit"
      ),
    meta: {
      title: "verbrauchswarenEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/sortiment/wareneinheiten",
    name: "wareneinheiten",
    component: () =>
      import(
        /* webpackChunkName: "wareneinheiten" */ "../views/sortiment/wareneinheiten"
      ),
    meta: {
      title: "wareneinheiten",
      userlevel: 2
    }
  },
  {
    path: "/:lang/sortiment/wareneinheiten/:id",
    name: "wareneinheitenEdit",
    component: () =>
      import(
        /* webpackChunkName: "wareneinheitenEdit" */ "../views/sortiment/wareneinheitenEdit"
      ),
    meta: {
      title: "wareneinheitenEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/kommission",
    name: "kommission",
    component: () =>
      import(/* webpackChunkName: "kommission" */ "../views/kommission"),
    meta: {
      title: "kommission",
      userlevel: 3
    }
  },
  {
    path: "/:lang/bestellungen",
    name: "bestellungen",
    component: () =>
      import(/* webpackChunkName: "bestellungen" */ "../views/bestellungen"),
    meta: {
      title: "bestellungen",
      userlevel: 3
    }
  },
  {
    path: "/:lang/lager",
    name: "lager",
    component: () => import(/* webpackChunkName: "lager" */ "../views/lager"),
    meta: {
      title: "lager",
      userlevel: 2
    }
  },
  {
    path: "/:lang/lager/:id",
    name: "lagerEdit",
    component: () =>
      import(/* webpackChunkName: "lagerEdit" */ "../views/lagerEdit"),
    meta: {
      title: "lagerEdit",
      userlevel: 2
    }
  },
  {
    path: "/:lang/einkauf",
    name: "einkauf",
    component: () =>
      import(/* webpackChunkName: "einkauf" */ "../views/einkauf"),
    meta: {
      title: "einkauf",
      userlevel: 2
    }
  },
  {
    path: "/:lang/einkauf/:id",
    name: "einkaufEdit",
    component: () =>
      import(/* webpackChunkName: "einkaufEdit" */ "../views/einkaufEdit"),
    meta: {
      title: "einkaufEdit",
      userlevel: 2
    }
  },
  {
    path: "*",
    redirect: "/de/home"
  }
];
