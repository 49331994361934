import axios from "axios";
import "./About.scss";

export default {
  data() {
    return {
      users: []
    };
  },
  mounted() {
    axios
      .get("/api/users/")
      .then(response => {
        this.users = response.data;
      })
      .catch(() => {});
  },
  render() {
    return (
      <div class="about">
        <h1>Welcome, {this.$store.state.username}</h1>
        Your userlevel is {this.$store.state.userlevel}.{" "}
        <a href="/api/logout">Log out</a>.
        {this.users.length > 0 && <h2>List of all users</h2>}
        {this.users.map(user => (
          <div>
            {user.username} - userlevel {user.userlevel}
          </div>
        ))}
      </div>
    );
  }
};
