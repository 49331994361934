import Vue from "vue";
import i18n from "../localization/i18n";
import VueRouter from "vue-router";
import store from "@/store";
import { routes } from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const allowedLanguages = ["de", "en", "si", "it"]; // Allowed languages for router check (else defaulting to de)

const routeConnectionsWithParams = {
  fangmethodenEdit: "fangmethoden",
  benutzerEdit: "benutzer",
  fanggebieteEdit: "fanggebiete",
  fischkennzeichnungenEdit: "fischkennzeichnungen",
  fischeEdit: "fische",
  filialenEdit: "filialen",
  kundenEdit: "kunden",
  lieferantenEdit: "lieferanten",
  verbrauchswarenEdit: "verbrauchswaren",
  fischeinheitenEdit: "fischeinheiten"
};

function handleSortQueryParams(to, from) {
  if (
    Object.keys(routeConnectionsWithParams).includes(from.name) &&
    (routeConnectionsWithParams[from.name] === to.name || from.name === to.name)
  ) {
    const hasQueryParams = !!Object.keys(from.query).length;
    if (hasQueryParams) {
      Object.assign(to.query, from.query);
    }
  } else {
    delete to.query.sortDesc;
    delete to.query.sortBy;
    delete to.query.search;
  }
}

router.beforeEach((to, from, next) => {
  try {
    handleSortQueryParams(to, from);

    // never go to login as "next"
    if (to.query.next && to.query.next.includes("login")) {
      to.query.next = from.query.next;
    }
    if (
      to.meta.userlevel > 4 ||
      (to.query.next && to.query.next.includes("mobile")) ||
      (from.meta.userlevel > 4 && to.name === "Login")
    ) {
      store.dispatch("mobile", true);
    } else {
      store.dispatch("mobile", false);
    }
    // Language Switcher
    allowedLanguages.indexOf(to.params.lang) > -1
      ? (i18n.locale = to.params.lang)
      : (i18n.locale = "de");
    if (
      to.matched.some(
        record =>
          record.meta.title !== "Login" &&
          isFinite(record.meta.userlevel) &&
          record.meta.userlevel < store.state.userlevel
      )
    ) {
      const lang = router.currentRoute.params.lang;
      next({
        path: `${lang ? "/" + lang : ""}/login`,
        query: { next: to.path }
      });
    } else {
      if (!to.redirectedFrom) {
        navigator.serviceWorker.controller?.postMessage({
          type: "SYNC_QUEUE"
        });
      }
      document.title = to.meta.title || "sofisch";
      next();
    }
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    // empty catch block
  }
});

export default router;
