import { Model } from "@vuex-orm/core";

export default class Mitarbeiter extends Model {
  static entity = "mitarbeiter";
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      vorname: this.string("").nullable(),
      nachname: this.string("").nullable(),
      userlevel: this.number(0),
      filiale: this.string("0").nullable(),
      anmerkung: this.string("").nullable()
    };
  }
}
