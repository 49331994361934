import { VDataTable, VTextField, VImg } from "vuetify/lib";
import goTo from "vuetify/lib/services/goto";
import Fischeinheiten from "@/models/fischeinheiten";
import Fisch from "@/models/fisch";

//import { v4 as uuidv4 } from "uuid";
import "../../overviewTables.scss";
import {
  updateSortValue,
  updateSortValuesFromQuery,
  highlight
} from "@/utils/helpers";

const nonSearchFields = [
  "foto",
  "$id",
  "id",
  "createdAt",
  "anmerkung",
  "nachgewicht",
  "searchText"
];

export default {
  props: ["target"],
  data() {
    return {
      search: "",
      sortBy: undefined,
      sortDesc: false,
      fisch: null,
      fischIds: []
    };
  },
  created() {
    Fischeinheiten.all().forEach(element => {
      if (this.fischIds.indexOf(element.fisch) == -1) {
        this.fischIds.push(element.fisch);
      }
    });
    this.fische = Fisch.query().whereIdIn(this.fischIds).get();
    // Filialen.query().where("verkauf", true).get();
  },
  mounted() {
    updateSortValuesFromQuery(this);
    if (
      this.$store.state.bestellungConfig.fish &&
      this.$store.state.bestellungConfig.fish != ""
    ) {
      goTo(".selectedTR", { container: ".v-data-table__wrapper" });
    }
  },
  watch: {
    search(value) {
      this.updateSortValue(this, "search", value);
    }
  },
  beforeDestroy() {},
  computed: {
    filteredFische() {
      return this.fische.filter(fisch => {
        fisch.searchText = "";
        const searchString = this.search ? this.search.toLowerCase() : "";
        if (!searchString) {
          fisch.searchText = "";
          return true;
        }
        let foundMatch;
        for (const key in fisch) {
          if (nonSearchFields.includes(key)) {
            continue;
          } else {
            const value = fisch[key] || "";
            if (value.toLowerCase().includes(searchString)) {
              if (key === "name_de") {
                const highlightedText = this.highlight(value, searchString);
                fisch.searchText = `<span class="font-weight-medium">${highlightedText}</span>`;
              } else if (key !== "gruppe") {
                const highlightedText = this.highlight(value, searchString);
                fisch.searchText = `<span class="font-weight-medium">${fisch.name_de}<span class="font-weight-regular"> (${highlightedText})</span></span>`;
              }
              foundMatch = true;
              if (key !== "gruppe") {
                return true;
              }
            }
          }
        }
        if (foundMatch) {
          return true;
        }
      });
    },
    headers() {
      return [
        { text: this.$t("label.photo"), value: "foto" },
        {
          text: this.$t("label.name"),
          value: "name_" + this.$route.params.lang
        },
        { text: this.$t("label.group"), value: "gruppe" }
      ];
    }
  },
  methods: {
    fishFilter(value, search, item) {
      return item["name_" + this.$route.params.lang].includes(search);
    },
    clearSelected() {
      this.$store.commit("bestellungConfig", {
        key: "fish",
        value: ""
      });
    },
    searchString(item) {
      this.search += item;
      this.clearSelected();
    },
    clearSearch() {
      this.search = "";
      this.clearSelected();
    },
    fishChosen(id) {
      this.$store.commit("bestellungConfig", {
        key: "fish",
        value: id
      });
      this.$emit("fish-chosen");
    },
    highlight,
    updateSortValue
  },
  render() {
    const scopedSlots = {
      item: item => {
        return (
          <tr
            class={
              item.item.id == this.$store.state.bestellungConfig.fish
                ? "selectedTR"
                : ""
            }
            onClick={this.fishChosen.bind(this, item.item.id)}
          >
            <td>
              <VImg
                class="ma-1"
                src={item.item.foto || "/img/no-image-placeholder.png"}
                height="80"
                width="80"
                contain
              ></VImg>
            </td>
            <td>
              <span
                domPropsInnerHTML={`<span> ${this.highlight(
                  item.item.searchText
                    ? item.item.searchText
                    : item.item["name_" + this.$route.params.lang],
                  this.search
                )}</span>`}
              ></span>
            </td>
            <td>
              <span
                domPropsInnerHTML={`<span> ${this.highlight(
                  item.item.gruppe,
                  this.search
                )}</span>`}
              ></span>
            </td>
          </tr>
        );
      },
      top: () => {
        return (
          <VTextField
            hide-details
            solo
            append-icon="mdi-magnify"
            v-model={this.search}
            class="customerHeadSearch pa-0 ma-0"
          ></VTextField>
        );
      }
    };
    return (
      <VDataTable
        itemKey="id"
        items={this.filteredFische}
        headers={this.headers}
        scopedSlots={scopedSlots}
        disable-pagination
        hide-default-footer
        height="calc(100vh - 125px)"
        sort-by={"name" + this.$route.params.lang}
        fixed-header
        sortBy={this.sortBy}
        sortDesc={this.sortDesc}
        on={{
          "update:sort-by": value =>
            this.updateSortValue(this, "sortBy", value),
          "update:sort-desc": value =>
            this.updateSortValue(this, "sortDesc", value)
        }}
      />
    );
  }
};
