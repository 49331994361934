import router from "../router";
import store from "../store";
import axios from "axios";
import { getHomePathForUserlevel } from "../utils/helpers";

export async function handleAuthenticated(user) {
  localStorage.setItem("sofisch", user);
  try {
    const response = await axios.get(`/api/users/${user}`);
    await store.dispatch("username", response.data.username);
    await store.dispatch("filiale", response.data.filiale);
    if (!store.state.bestellungConfig.store) {
      store.commit("bestellungConfig", {
        key: "store",
        value: response.data.filiale
      });
    }
    await store.dispatch("userlevel", response.data.userlevel);
    if (router.currentRoute.path.endsWith("/login")) {
      let route = router.currentRoute.query?.next;
      if (!route || route.endsWith("/home")) {
        route = `/${
          router.currentRoute.params.lang || "de"
        }${getHomePathForUserlevel(store.state.userlevel)}`;
      }
      router.push(route).catch(() => {});
    }
  } catch (e) {
    // empty catch block
  }
}

function goToLogin() {
  if (!router.currentRoute.path.endsWith("/login")) {
    localStorage.removeItem("sofisch");
    const lang = router.currentRoute.params.lang;
    const route = {
      path: `${lang ? "/" + lang : ""}/login`
    };
    if (!location.pathname.endsWith("/logout")) {
      route.query = { next: location.pathname };
    }
    router.push(route).catch(() => {});
  }
}

const auth = app => {
  app.$on("authenticated", handleAuthenticated);
  app.$on("unauthenticated", goToLogin);
};

export default auth;
