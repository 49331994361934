import { Model } from "@vuex-orm/core";

export default class Laendernamen extends Model {
  static entity = "laendername";
  static fields() {
    return {
      id: this.attr(null),
      name_de: this.string("").nullable(),
      name_en: this.string("").nullable(),
      name_fr: this.string("").nullable(),
      name_it: this.string("").nullable(),
      name_sp: this.string("").nullable(),
      name_sl: this.string("").nullable(),
      name_hr: this.string("").nullable(),
      name_ru: this.string("").nullable()
    };
  }
}
