import { VBtn, VCard, VCardTitle, VCol, VContainer, VRow } from "vuetify/lib";

export default {
  render() {
    return (
      <VContainer fluid>
        <VRow>
          <VCol>
            <VCard class="ma-4" color="rgba(255,250,240,.7)">
              <VCardTitle>Sofisch Mobile Home</VCardTitle>
              <VBtn to="Verkauf" class="ma-4" large width="250" color="#fff">
                {this.$t("menu.disposal")}
              </VBtn>
            </VCard>
          </VCol>
        </VRow>
      </VContainer>
    );
  }
};
