/* eslint-disable no-console */

import { register } from "register-service-worker";
import { handleAuthenticated } from "./plugins/auth";

/**
 * @param {Vue} eventBus
 * @returns {Promise<void>}
 * @emits appUpdated
 * @emits queueCountUpdated
 * @emits authenticated
 * @emits unauthenticated
 */
export default async eventBus => {
  if (process.env.NODE_ENV === "production") {
    register(`${process.env.BASE_URL}service-worker.js`, {
      async ready() {
        console.log(
          "App is being served from cache by a service worker.\n" +
            "For more details, visit https://goo.gl/AFskqB"
        );
        navigator.serviceWorker.onmessage = event => {
          if (event.data) {
            switch (event.data.type) {
              case "QUEUE_COUNT":
                eventBus.$emit("queueCountUpdated", event.data.size);
                break;
              case "AUTHENTICATED":
                eventBus.$emit("authenticated", event.data.user);
                break;
              case "UNAUTHENTICATED":
                eventBus.$emit("unauthenticated");
                break;
              case "GET_USER":
                navigator.serviceWorker.controller?.postMessage({
                  type: "REPLY_GET_USER",
                  user: localStorage.getItem("sofisch")
                });
                break;
              default:
            }
          }
        };
        if (localStorage.getItem("sofisch")) {
          navigator.serviceWorker.controller?.postMessage({
            type: "RECOVER_USER"
          });
        }
        navigator.serviceWorker.controller?.postMessage({
          type: "GET_QUEUE_COUNT"
        });
      },
      registered() {
        console.log("Service worker has been registered.");
      },
      cached() {
        console.log("Content has been cached for offline use.");
      },
      updatefound() {
        console.log("New content is downloading.");
      },
      updated() {
        console.log("New content is available; please refresh.");
        eventBus.$emit("appUpdated");
      },
      offline() {
        console.log(
          "No internet connection found. App is running in offline mode."
        );
      },
      error(error) {
        console.error("Error during service worker registration:", error);
      }
    });
  } else {
    const originalFetch = fetch;
    window.fetch = async (input, init) => {
      const user = localStorage.getItem("sofisch");
      if (input === "/api/login") {
        const loginResponse = await originalFetch(input, init);
        const loginJson = await loginResponse.json();
        eventBus.$emit("authenticated", loginJson.id);
      } else if (input === "/api/logout") {
        eventBus.$emit("unauthenticated");
      } else if (user) {
        const refreshTokenResponse = await originalFetch("/api/refresh-token", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ user })
        });
        const { access_token } = await refreshTokenResponse.json();
        const headers = {
          ...init?.headers,
          Authorization: `Bearer ${access_token}`
        };
        init = { ...init, headers };
      }
      const response = await originalFetch(input, init);
      if (response.status === 401) {
        console.log("Unauthorized request:", input, init);
      }
      return response;
    };
    const knownUser = localStorage.getItem("sofisch");
    if (knownUser) {
      await handleAuthenticated(knownUser);
    }
  }
};
