import {
  VForm,
  VSelect,
  VTextField,
  VCard,
  VBtn,
  VCol,
  VRow,
  VIcon
} from "vuetify/lib";
import axios from "axios";
import "./Login.scss";

export default {
  name: "loginMobile",
  data() {
    return {
      isEinkauf: false,
      password: [],
      usernames: [],
      isFormValid: false
    };
  },
  computed: {
    rules() {
      return {
        required: value => !!value || this.$t("error.obligatory")
      };
    }
  },
  created() {
    if (
      typeof this.$route.query.next != "undefined" &&
      this.$route.query.next.indexOf("einkauf") > -1
    ) {
      this.isEinkauf = true;
    }
  },
  mounted() {
    this.$refs.focusMe.focus();
    const userType = this.isEinkauf ? "usersEinkauf" : "usersVerkauf";
    axios.get("/api/" + userType).then(response => {
      this.usernames.push(...response.data);
    });
  },
  methods: {
    addNumber(value) {
      this.password.push(value);
    },

    async submit(e) {
      e.preventDefault();
      try {
        await axios.post("/api/login", {
          username: this.username,
          password: this.password.join("")
        });
      } catch (error) {
        this.$refs.form.reset();
        this.$refs.focusMe.focus();
        this.password.length = 0;
      }
    }
  },
  render() {
    return (
      <div class="pt-5 d-flex justify-center">
        <VCard width="450px" class="pa-5" elevation="4">
          <VForm ref="form" onSubmit={this.submit} v-model={this.isFormValid}>
            <VSelect
              items={this.usernames}
              rules={[this.rules.required]}
              ref="focusMe"
              onInput={e => (this.username = e)}
              label={this.$t("label.username")}
              placeholder={this.$t("label.username")}
              single
              outlined
              required
            />
            <VTextField
              readonly
              label="PIN"
              required
              single
              outlined
              class="centered-input"
              value={this.password.map(() => "  *  ").join("")}
            />
            <VRow>
              <VCol class="d-flex justify-center">
                <VBtn
                  fab
                  large
                  retain-focus-on-click
                  ripple={false}
                  onClick={() => this.addNumber(1)}
                >
                  1
                </VBtn>
              </VCol>
              <VCol class="d-flex justify-center">
                <VBtn
                  fab
                  large
                  retain-focus-on-click
                  ripple={false}
                  onClick={() => this.addNumber(2)}
                >
                  2
                </VBtn>
              </VCol>
              <VCol class="d-flex justify-center">
                <VBtn
                  fab
                  large
                  retain-focus-on-click
                  ripple={false}
                  onClick={() => this.addNumber(3)}
                >
                  3
                </VBtn>
              </VCol>
            </VRow>

            <VRow>
              <VCol class="d-flex justify-center">
                <VBtn
                  fab
                  large
                  retain-focus-on-click
                  ripple={false}
                  onClick={() => this.addNumber(4)}
                >
                  4
                </VBtn>
              </VCol>
              <VCol class="d-flex justify-center">
                <VBtn
                  fab
                  large
                  retain-focus-on-click
                  ripple={false}
                  onClick={() => this.addNumber(5)}
                >
                  5
                </VBtn>
              </VCol>
              <VCol class="d-flex justify-center">
                <VBtn
                  fab
                  large
                  retain-focus-on-click
                  ripple={false}
                  onClick={() => this.addNumber(6)}
                >
                  6
                </VBtn>
              </VCol>
            </VRow>
            <VRow>
              <VCol class="d-flex justify-center">
                <VBtn
                  fab
                  large
                  retain-focus-on-click
                  ripple={false}
                  onClick={() => this.addNumber(7)}
                >
                  7
                </VBtn>
              </VCol>
              <VCol class="d-flex justify-center">
                <VBtn
                  fab
                  large
                  retain-focus-on-click
                  ripple={false}
                  onClick={() => this.addNumber(8)}
                >
                  8
                </VBtn>
              </VCol>
              <VCol class="d-flex justify-center">
                <VBtn
                  fab
                  large
                  retain-focus-on-click
                  ripple={false}
                  onClick={() => this.addNumber(9)}
                >
                  9
                </VBtn>
              </VCol>
            </VRow>
            <VRow>
              <VCol class="d-flex justify-center">
                <VBtn
                  fab
                  large
                  retain-focus-on-click
                  ripple={false}
                  onClick={() => {
                    this.password.pop();
                  }}
                  disabled={!this.password.length}
                >
                  <VIcon>mdi-backspace</VIcon>
                </VBtn>
              </VCol>
              <VCol class="d-flex justify-center">
                <VBtn
                  fab
                  large
                  retain-focus-on-click
                  ripple={false}
                  onClick={() => this.addNumber(0)}
                >
                  0
                </VBtn>
              </VCol>
              <VCol class="d-flex justify-center">
                <VBtn
                  fab
                  large
                  retain-focus-on-click
                  ripple={false}
                  disabled={!this.isFormValid}
                  type="submit"
                >
                  <VIcon>mdi-check-outline</VIcon>
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCard>
      </div>
    );
  }
};
