import { VCard, VCol, VContainer, VIcon, VRow } from "vuetify/lib";

export default {
  render() {
    return (
      <VContainer fluid>
        <VRow>
          <VCol cols="2"></VCol>
          <VCol cols="4">
            <VCard
              to={"/" + this.$route.params.lang + "/administration"}
              class="ma-4 pa-6 text-center"
              rounded="xl"
              elevation="7"
            >
              <VIcon size="150">mdi-cog-outline</VIcon>
              <br />
              <span class="text-h5 font-weight-black">
                {this.$t("menu.administration")}
              </span>
            </VCard>
          </VCol>
          <VCol cols="4">
            <VCard
              to="mobile/verkauf"
              class="ma-4 pa-6 text-center"
              rounded="xl"
              elevation="7"
            >
              <VIcon size="150">mdi-storefront-outline</VIcon>
              <br />
              <span class="text-h5 font-weight-black">
                {this.$t("menu.disposal")}
              </span>
            </VCard>
          </VCol>
          <VCol cols="2"></VCol>
        </VRow>
      </VContainer>
    );
  }
};
