import {
  VBtn,
  VCard,
  VCardSubtitle,
  VCardTitle,
  VCol,
  VContainer,
  VRow
} from "vuetify/lib";

export default {
  render() {
    return (
      <VContainer fluid>
        <VRow>
          <VCol>
            <VCard class="ma-4" color="rgba(240,255,240,.7)">
              <VCardTitle>System</VCardTitle>
              <VCardSubtitle>
                sofisch|system {this.$t("menu.systemdata")}
              </VCardSubtitle>
              <VBtn
                to={`/${this.$route.params.lang}/system/benutzer`}
                class="ma-4"
                large
                elevation="3"
                width="250"
                color="#fff"
              >
                {this.$t("menu.users")}
              </VBtn>
              <VBtn
                to={`/${this.$route.params.lang}/system/mitarbeiter`}
                class="ma-4"
                large
                elevation="3"
                width="250"
                color="#fff"
              >
                {this.$t("menu.employees")}
              </VBtn>
            </VCard>
          </VCol>
          <VCol></VCol>
        </VRow>
      </VContainer>
    );
  }
};
