import { Database } from "@vuex-orm/core";
import Fisch from "@/models/fisch";
import Kunden from "@/models/kunden";
import Users from "@/models/users";
import Mitarbeiter from "@/models/mitarbeiter";
import Lieferanten from "@/models/lieferanten";
import Laendernamen from "@/models/laendernamen";
import Wareneinheiten from "@/models/wareneinheiten";
import Fanggebiete from "@/models/fanggebiete";
import Fangmethoden from "@/models/fangmethoden";
import Filialen from "@/models/filialen";
import Fischeinheiten from "@/models/fischeinheiten";
import Fischkennzeichnungen from "@/models/fischkennzeichnungen";
import Verbrauchswaren from "@/models/verbrauchswaren";
import Bestellungen from "@/models/bestellungen";
import Einkauf from "@/models/einkauf";

const database = new Database();

database.register(Fisch);
database.register(Kunden);
database.register(Users);
database.register(Mitarbeiter);
database.register(Lieferanten);
database.register(Laendernamen);
database.register(Wareneinheiten);
database.register(Fanggebiete);
database.register(Fangmethoden);
database.register(Filialen);
database.register(Fischeinheiten);
database.register(Fischkennzeichnungen);
database.register(Verbrauchswaren);
database.register(Bestellungen);
database.register(Einkauf);

export default database;
