import {
  VBtn,
  VCard,
  VCardSubtitle,
  VCardTitle,
  VCol,
  VContainer,
  VRow
} from "vuetify/lib";

export default {
  render() {
    return (
      <VContainer fluid>
        <VRow>
          <VCol cols="12" lg="5">
            <VCard class="ma-3" color="rgba(255,250,240,.7)">
              <VCardTitle>{this.$t("menu.assortment")}</VCardTitle>
              <VCardSubtitle>
                sofisch|system {this.$t("menu.assortment")}{" "}
                {this.$t("menu.data")}
              </VCardSubtitle>
              <VBtn
                to="stammdaten/fische"
                class="ma-3"
                large
                elevation="3"
                width="calc(50% - 30px)"
                color="#fff"
              >
                {this.$t("menu.fish")}
              </VBtn>
              <VBtn
                to="stammdaten/verbrauchswaren"
                class="ma-3"
                large
                elevation="3"
                width="calc(50% - 30px)"
                color="#fff"
              >
                {this.$t("menu.consumables")}
              </VBtn>
              <VBtn
                to={"/" + this.$route.params.lang + "/sortiment/fischeinheiten"}
                class="ma-3"
                large
                elevation="3"
                width="calc(50% - 30px)"
                color="#fff"
              >
                {this.$t("menu.fishunits")}
              </VBtn>
              <VBtn
                to={"/" + this.$route.params.lang + "/sortiment/wareneinheiten"}
                class="ma-3"
                large
                elevation="3"
                width="calc(50% - 30px)"
                color="#fff"
              >
                {this.$t("menu.consumableunits")}
              </VBtn>{" "}
            </VCard>
            <VCard class="ma-3" color="rgba(240,230,240,.9)">
              <VCardTitle>{this.$t("menu.procurement")}</VCardTitle>
              <VCardSubtitle>
                sofisch|system {this.$t("menu.procurement")}{" "}
                {this.$t("menu.data")}
              </VCardSubtitle>
              <VBtn
                to="einkauf"
                class="ma-3"
                large
                elevation="3"
                width="calc(50% - 30px)"
                color="#fff"
              >
                {this.$t("menu.procurement")}
              </VBtn>
            </VCard>
          </VCol>
          <VCol cols="12" lg="7">
            <VCard class="ma-3" color="rgba(240,250,255,.7)">
              <VCardTitle>
                {this.$t("menu.system")} / {this.$t("menu.standingdata")}
              </VCardTitle>
              <VCardSubtitle>
                sofisch|system {this.$t("menu.systemdata")}
              </VCardSubtitle>
              <VRow no-gutters>
                <VBtn
                  to={`/${this.$route.params.lang}/system/benutzer`}
                  class="ma-3"
                  large
                  elevation="3"
                  width="calc(33% - 30px)"
                  color="#fff"
                >
                  {this.$t("menu.users")}
                </VBtn>
                <VBtn
                  to={`/${this.$route.params.lang}/system/mitarbeiter`}
                  class="ma-3"
                  large
                  elevation="3"
                  width="calc(33% - 30px)"
                  color="#fff"
                >
                  {this.$t("menu.employees")}
                </VBtn>
              </VRow>
              <VRow no-gutters>
                <VBtn
                  to="stammdaten/filialen"
                  class="ma-3"
                  large
                  elevation="3"
                  width="calc(33% - 30px)"
                  color="#fff"
                >
                  {this.$t("menu.stores")}
                </VBtn>
                <VBtn
                  to="stammdaten/kunden"
                  class="ma-3"
                  large
                  elevation="3"
                  width="calc(33% - 30px)"
                  color="#fff"
                >
                  {this.$t("menu.customers")}
                </VBtn>
                <VBtn
                  to="stammdaten/lieferanten"
                  class="ma-3"
                  large
                  elevation="3"
                  width="calc(33% - 30px)"
                  color="#fff"
                >
                  {this.$t("menu.providers")}
                </VBtn>
              </VRow>
              <VRow no-gutters>
                <VBtn
                  to="stammdaten/fanggebiete"
                  class="ma-3"
                  large
                  elevation="3"
                  width="calc(33% - 30px)"
                  color="#fff"
                >
                  {this.$t("menu.fishinggrounds")}
                </VBtn>
                <VBtn
                  to="stammdaten/fangmethoden"
                  class="ma-3"
                  large
                  elevation="3"
                  width="calc(33% - 30px)"
                  color="#fff"
                >
                  {this.$t("menu.fishingmethods")}
                </VBtn>
                <VBtn
                  to="stammdaten/fischkennzeichnungen"
                  class="ma-3"
                  large
                  elevation="3"
                  width="calc(33% - 30px)"
                  color="#fff"
                >
                  {this.$t("menu.fishlabels")}
                </VBtn>
              </VRow>
            </VCard>
            <VCard class="ma-3" color="rgba(240,250,255,.7)">
              <VCardTitle>{this.$t("menu.registrierkasse")}</VCardTitle>
              <VCardSubtitle>
                sofisch|system {this.$t("menu.systemdata")}
              </VCardSubtitle>
              <VRow no-gutters>
                <VBtn
                  to={"/" + this.$route.params.lang + "/system/registrierkasse"}
                  class="ma-3"
                  large
                  elevation="3"
                  width="calc(33% - 30px)"
                  color="#fff"
                >
                  {this.$t("menu.registrierkasse")}
                </VBtn>
                <VBtn
                  to={"/" + this.$route.params.lang + "/system/artikel"}
                  class="ma-3"
                  large
                  elevation="3"
                  width="calc(33% - 30px)"
                  color="#fff"
                >
                  {this.$t("menu.items")}
                </VBtn>
              </VRow>
            </VCard>
          </VCol>
        </VRow>
      </VContainer>
    );
  }
};
