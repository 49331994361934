import {
  VCard,
  VRow,
  VCol,
  VIcon,
  VBtn,
  VList,
  VListItem,
  VOverlay
} from "vuetify/lib";
import { v4 as uuidv4 } from "uuid";
import Filialen from "@/models/filialen";
import DatumAuswahl from "./components/datumAuswahl";
import BestellungAuswahl from "./components/bestellungAuswahl";
import KundenAuswahl from "./components/kundenAuswahl";
import "./mobileClasses.scss";

export default {
  data() {
    return {
      navDrawer: false,
      users: [],
      filialen: null,
      pointOfSale: null,
      filialeAktuell: this.$store.state.bestellungConfig.store,
      datumAktuell: null,
      customerChoose: false,
      customerChoice: false
    };
  },
  mounted() {},
  methods: {
    customerEdit() {
      this.$router.push({
        path:
          "/" +
          this.$route.params.lang +
          `/mobile/kunden/${this.$store.state.bestellungConfig.customer}`
      });
    },
    customerClose() {
      this.$store.commit("bestellungConfig", {
        key: "savedsearch",
        value: ""
      });
      this.$store.commit("bestellungConfig", {
        key: "customer",
        value: ""
      });
      this.customerChoice = false;
      this.customerChoose = false;
    },
    customerChosen() {
      this.customerChoice = true;
    },
    newItem(what) {
      this.$store.commit("bestellungConfig", {
        key: "ordermode",
        value: what
      });
      this.$store.commit("bestellungConfig", {
        key: "rendermode",
        value: "neworder"
      });
      this.$router.push({ path: `./bestellungen/${uuidv4()}` });
    }
  },
  created() {
    if (this.$store.state.bestellungConfig.rendermode == "additem") {
      this.$router.push({ path: `./bestellungen/${uuidv4()}` });
    }
    if (this.$store.state.bestellungConfig.rendermode == "goitem") {
      this.$store.commit("bestellungConfig", {
        key: "rendermode",
        value: "editorder"
      });
      this.$router.push({
        path: `./bestellungen/${this.$store.state.bestellungConfig.lastid}`
      });
    }
    this.filialen = Filialen.all();
    this.pointOfSale = this.filialen.filter(filiale => filiale.verkauf == 1);
    if (this.$store.state.bestellungConfig.customer != "") {
      this.customerChoose = true;
      this.customerChoice = true;
    }
  },

  render() {
    const orderPage = {};
    const homeButton =
      this.$store.state.userlevel < 8 ? (
        <VListItem
          onClick={() =>
            this.$router.push({
              path: "/" + this.$route.params.lang + "/home"
            })
          }
        >
          <VIcon class="mr-3">mdi-home</VIcon>
          {this.$t("navigation.home")}
        </VListItem>
      ) : (
        <div />
      );
    orderPage["orders"] = (
      <div>
        {this.navDrawer && (
          <VOverlay value="navDrawer" z-index="10000">
            <VList>
              {homeButton}
              <VListItem
                onClick={() =>
                  this.$router.push({
                    path: "/" + this.$route.params.lang + "/logout"
                  })
                }
              >
                <VIcon class="mr-3">mdi-logout</VIcon>
                {this.$t("navigation.logout")}
              </VListItem>
              <VListItem onClick={() => (this.navDrawer = !this.navDrawer)}>
                <VIcon class="mr-3">mdi-close</VIcon>
                {this.$t("button.cancel")}
              </VListItem>
            </VList>
          </VOverlay>
        )}
        <VCard class="bestellungenCard">
          <BestellungAuswahl />
        </VCard>

        {this.customerChoose && (
          <VCard class="floatingCard">
            <VRow no-gutters class="heading">
              <VCol cols="2" class="pa-2">
                <VBtn fab small>
                  <VIcon color="grey">mdi-card-account-details</VIcon>
                </VBtn>
              </VCol>
              <VCol cols="10" class="pa-2 d-flex justify-end">
                <span class="white--text text-h6 ma-1">
                  {this.$t("header.choosecustomer")}
                </span>
              </VCol>
            </VRow>
            <VRow no-gutters class="mt-0 pa-1">
              <VCol>
                <KundenAuswahl
                  on-customer-chosen={this.customerChosen}
                  on-customer-edit={this.customerEdit}
                />
              </VCol>
            </VRow>
          </VCard>
        )}

        <VRow no-gutters class="heading">
          <VCol cols="1" class="pa-2">
            <VBtn
              width="45"
              height="45"
              fab
              small
              onClick={() => (this.navDrawer = !this.navDrawer)}
            >
              <VIcon color="grey">mdi-dots-vertical</VIcon>
            </VBtn>
          </VCol>
          <VCol cols="11" class="pa-2 d-flex justify-end">
            <DatumAuswahl />
          </VCol>
        </VRow>
        <VRow no-gutters class="mt-2 pa-1 bestellungenFillHeight">
          <VCol></VCol>
        </VRow>
        <VRow class="mt-2 bottomRow" no-gutters>
          <VCol cols="3" align="left">
            {this.customerChoose && (
              <VBtn
                width="90%"
                large
                color="red"
                class="ma-1"
                dark
                onClick={() => this.customerClose()}
              >
                <VIcon large>mdi-close-thick</VIcon>
              </VBtn>
            )}
            {!this.customerChoose && (
              <VBtn
                width="90%"
                large
                color="blue"
                class="ma-1"
                dark
                onClick={() => (this.customerChoose = true)}
              >
                <VIcon large>mdi-account</VIcon>
              </VBtn>
            )}
          </VCol>
          <VCol cols="3" align="left">
            {this.customerChoose && !this.customerChoice && (
              <VBtn
                width="90%"
                large
                color="blue"
                class="ma-1"
                dark
                onClick={() =>
                  this.$router.push({ path: `./kunden/${uuidv4()}` })
                }
              >
                <VIcon large>mdi-account-plus</VIcon>
              </VBtn>
            )}
          </VCol>
          <VCol cols="3" align="right">
            {(!this.customerChoose || this.customerChoice) && (
              <VBtn
                width="90%"
                large
                color="#008B8B"
                dark
                class="ma-1"
                onClick={() => this.newItem("goods")}
              >
                <VIcon large>mdi-package-variant-closed</VIcon>
                <VIcon class="ml-2">mdi-plus-thick</VIcon>
              </VBtn>
            )}
          </VCol>
          <VCol cols="3" align="right">
            {(!this.customerChoose || this.customerChoice) && (
              <VBtn
                width="90%"
                large
                color="green"
                dark
                class="ma-1"
                onClick={() => this.newItem("fish")}
              >
                <VIcon large>mdi-fish</VIcon>
                <VIcon class="ml-2">mdi-plus-thick</VIcon>
              </VBtn>
            )}
          </VCol>
        </VRow>
      </div>
    );

    return (
      <VCard
        color="rgba(255,255,255,.9)"
        width="100%"
        elevation="0"
        class="fill-height ma-0 pa-0"
      >
        {orderPage["orders"]}
      </VCard>
    );
  }
};
