/**
 * helper function to get all unique values of all instances of a model
 * for a given property name. Useful for populating comboboxes
 * @param {*} model
 * @param {*} attributeName
 * @returns {Array} array of unique strings
 */
export function getComboboxValues(model, attributeName) {
  const allInstances = model.all();
  return Array.from(
    new Set(
      allInstances.map(item =>
        item[attributeName] == null ? "" : item[attributeName]
      )
    )
  ).sort();
}

/**
 * persists an item both in vuex and on the server
 * @param {*} model e.g. "Fisch"
 * @param {string} id id of the item
 * @param {Object} componentInstance vue component ("this")
 * @param {string} endpoint api endpoint for persisting data on server
 * @returns {Promise} promise that resolves when server-upload is finished
 */
export function persistItem(model, id, componentInstance, endpoint) {
  const existingItem = model.find(id) || {};
  const newData = {};
  // extract all updated values from the component instances computed properties
  const fields = model.fields();
  for (const field in fields) {
    newData[field] = componentInstance[field];
  }
  newData.id = id;
  // persist data in vuex
  model.insertOrUpdate({
    data: [newData]
  });

  // ...and on the server
  if (endpoint === "users") {
    if (componentInstance.password) {
      newData.password = componentInstance.password;
    } else {
      delete newData.password;
    }
  }
  if (existingItem.createdAt) {
    return model.api().put(`/api/${endpoint}/${id}`, newData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  } else {
    return model.api().post(`/api/${endpoint}/${id}`, newData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
}

/**
 * deletes an item both in vuex and on the server
 * @param {*} model e.g. "Fisch"
 * @param {string} id id of the item
 * @param {string} endpoint api endpoint for persisting data on server
 * @returns {Promise} promise that resolves when the server-sided deletion is finished
 */
export function deleteItem(model, id, endpoint) {
  return model.api().delete(`/api/${endpoint}/${id}`);
}

/**
 * updates a value when sorting via v-data-table headers
 * to be used either with sort-by or sort-desc
 * handles transformation into array when clicking the same header multiple times
 * @param {*} vueInstance
 * @param {*} key
 * @param {*} value
 */
export function updateSortValue(vueInstance, key, value) {
  const newValue = Array.isArray(value) ? value[0] : value;
  vueInstance[key] = newValue;
}

/**
 * updates component data with sort values from query
 * should be called in mounted hoook
 * @param {*} vueInstance
 */
export function updateSortValuesFromQuery(vueInstance) {
  const query = vueInstance.$router.currentRoute.query;
  if (query.sortBy) {
    vueInstance.sortBy = query.sortBy;
  }
  if (query.sortDesc) {
    vueInstance.sortDesc = query.sortDesc;
  }
  if (query.search) {
    vueInstance.search = query.search;
  }
}

/**
 * generates highlighted from string and search query
 */
export function highlight(value, query) {
  if (!value) {
    return "";
  }
  if (!query) {
    return value;
  }
  // get this value to not lose the uppercase (if any)
  const replaceValue = value.match(new RegExp(query, "ig"));
  const highlightSubstr = replaceValue ? replaceValue[0] : "";
  const highlightedText = value.replace(
    new RegExp(query, "i"),
    "<span class='yellow'>" + highlightSubstr + "</span>"
  );
  return highlightedText;
}

/**
 * returns the predefined home path for a given userlevel (without localization)
 * @param {number} userlevel
 * @returns {string}
 */
export function getHomePathForUserlevel(userlevel) {
  const loginPath =
    userlevel === 8
      ? "/mobile/verkauf"
      : userlevel === 32
      ? "/mobile/einkauf"
      : "/";
  return loginPath;
}

/**
 * @typedef {number} weekNumber Number representing the week, in the format of YYYYWW
 */

/**
 * this generates a year + week number in the format YYYYWW as a number
 * @param {Date} date
 * @returns {weekNumber} week number in YYYYWW
 */
export function getWeekNumber(date) {
  const dayNumber = (date.getDay() + 6) % 7;
  date.setDate(date.getDate() - dayNumber + 3);
  const firstThursday = date.valueOf();
  date.setMonth(0, 1);
  if (date.getDay() !== 4) {
    date.setMonth(0, 1 + ((4 - date.getDay() + 7) % 7));
  }
  const year = date.getFullYear() * 100;
  return year + 1 + Math.ceil((firstThursday - date) / 604800000);
}
