import { Model } from "@vuex-orm/core";

export default class einkauf extends Model {
  static entity = "einkauf";
  static fields() {
    return {
      createdAt: this.attr(undefined),
      updatedAt: this.attr(undefined),
      id: this.attr(null),
      einkaufnummer: this.string("").nullable(),
      nummerintern: this.string("").nullable(),
      filiale: this.string("").nullable(),
      datum: this.string("").nullable(),
      typ: this.string("").nullable(),
      fischeinheit: this.string("").nullable(),
      wareneinheit: this.string("").nullable(),
      bezahlung: this.string("").nullable(),
      anzahl: this.number(1),
      gewicht: this.number(0).nullable(),
      lieferant: this.string("").nullable(),
      kunde: this.string("").nullable(),
      status: this.string("").nullable(),
      anmerkung: this.string("").nullable(),
      fangmethode: this.string("").nullable(),
      fanggebiet: this.string("").nullable(),
      land: this.string("").nullable(),
      einkaufspreis: this.number(0).nullable(),
      ust: this.number(0).nullable(),
      los: this.string("").nullable(),
      permanent: this.boolean(false).nullable()
    };
  }
}
