import {
  VBtn,
  VDataTable,
  VIcon,
  VRow,
  VCol,
  VDialog,
  VCard,
  VImg
} from "vuetify/lib";
import "../mobileClasses.scss";
import FilialeAuswahl from "./filialeAuswahl";
import Bestellungen from "@/models/bestellungen";
import Kunden from "@/models/kunden";
import Fische from "@/models/fisch";
import Filialen from "@/models/filialen";
import Wareneinheiten from "@/models/wareneinheiten";
import Waren from "@/models/verbrauchswaren";
import Fischkennzeichnungen from "@/models/fischkennzeichnungen";
import { bestellungStatus } from "@/utils/constants";
import "../../overviewTables.scss";
import { deleteItem } from "@/utils/helpers";
import Fisch from "../../../models/fisch";

export default {
  data() {
    return {
      neueLagernummer: "",
      alteLagernummer: "",
      currentOrderId: "",
      currentOrderTyp: "",
      listmode: this.$store.state.bestellungConfig.listmode,
      modal: false,
      lagermodal: false,
      filterStatus: [],
      filterZustellung: true,
      filterRueckruf: true,
      statusIcons: {},
      kunden: Kunden.all(),
      sortBy: "datumabholung",
      sortDesc: false,
      itemCount: 0,
      optionsTime: { hour: "2-digit", minute: "2-digit" },
      optionsDay: { weekday: "short", month: "2-digit", day: "2-digit" }
    };
  },
  created() {
    this.$store.commit("bestellungConfig", {
      key: "searchstring",
      value: ""
    });
    bestellungStatus.forEach(einstatus => {
      this.statusIcons[einstatus.key] = {
        icon: einstatus.icon,
        color: einstatus.color,
        class: einstatus.class
      };
    });
  },
  mounted() {
    if (this.$store.state.bestellungConfig.laststatus) {
      this.statusFilterSet(this.$store.state.bestellungConfig.laststatus);
    }
    if (Number(this.$store.state.bestellungConfig.lagernummergesamt) != "") {
      this.$refs[
        "b" + this.$store.state.bestellungConfig.lagernummergesamt
      ].scrollIntoView({ block: "center" });
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("label.orderitem"),
          value: "anzeigename",
          sortable: this.listmode == "prepare" ? true : false
        },
        {
          text: this.$t("label.pieces"),
          value: "anzahl",
          sortable: false
        },
        {
          text: this.$t("label.weightfromshort"),
          value: "gewicht",
          sortable: false
        },
        {
          text: this.$t("label.weighttoshort"),
          value: "gewichtbis",
          sortable: false
        },
        {
          text: this.$t("label.labels"),
          value: "fischkennzeichnungen",
          sortable: false
        },
        {
          text: this.$t("label.status"),
          value: "status",
          filterable: true,
          sortable: this.listmode == "prepare" ? true : false
        }
      ];
    },
    kundeAktuell() {
      if (this.currentOrderId) {
        const kunde = Kunden.find(Bestellungen.find(this.currentOrderId).kunde);
        return (
          <VRow>
            <VCol cols="7">
              {kunde
                ? (kunde.nachname ? kunde.nachname : "") +
                  " " +
                  (kunde.vorname ? kunde.vorname : "")
                : "-"}
              <br />
              {kunde ? (kunde.adresse ? kunde.adresse : "") : "-"}
              <br />
              {kunde
                ? (kunde.plz ? kunde.plz : "") +
                  " " +
                  (kunde.ort ? kunde.ort : "")
                : "-"}
            </VCol>
            <VCol cols="5" align="right">
              {kunde ? (kunde.mobil ? kunde.mobil : "") : "-"}
              <br />
              {kunde ? (kunde.telefon ? kunde.telefon : "") : "-"}
            </VCol>
          </VRow>
        );
      } else {
        return "";
      }
    },
    bestellungSummen() {
      const statusFilterArray =
        this.filterStatus.length == 0
          ? ["VB", "NV", "OK", "AH", "ZG"]
          : this.filterStatus;
      const startDatum = this.$store.state.bestellungConfig.datum;
      let endDatum = new Date(startDatum);
      endDatum.setDate(
        startDatum.getDate() + (this.listmode == "preview" ? 7 : 1)
      );
      const startString = startDatum.toISOString().substr(0, 10) + " 00:00:00";
      const endString =
        this.listmode == "preview"
          ? endDatum.toISOString().substr(0, 10) + " 23:59:59"
          : endDatum.toISOString().substr(0, 10) + " 02:00:00";
      let tempAlle = Bestellungen.query()
        .where(order => {
          return (
            (order.zustellung || this.filterZustellung) &&
            (order.rueckruf || this.filterRueckruf) &&
            statusFilterArray.includes(order.status) &&
            order.filialeabholung == this.$store.state.bestellungConfig.store &&
            order.datumabholung >= startString &&
            order.datumabholung <= endString
          );
        })
        .orderBy("datumabholung")
        .get();
      const sumFisch = {};
      const sumWare = {};
      for (let posten in tempAlle) {
        if (tempAlle[posten].typ == "fisch") {
          if (typeof sumFisch[tempAlle[posten].fisch] != "undefined") {
            sumFisch[tempAlle[posten].fisch].count += tempAlle[posten].anzahl;
            sumFisch[tempAlle[posten].fisch].vongramm +=
              tempAlle[posten].anzahl * tempAlle[posten].gewicht;
            sumFisch[tempAlle[posten].fisch].bisgramm +=
              tempAlle[posten].anzahl *
              (tempAlle[posten].gewichtbis > 0
                ? tempAlle[posten].gewichtbis
                : tempAlle[posten].gewicht);
          } else {
            sumFisch[tempAlle[posten].fisch] = {
              name: Fisch.find(tempAlle[posten].fisch)
                ? Fisch.find(tempAlle[posten].fisch)[
                    "name_" + this.$route.params.lang
                  ]
                : "",
              count: tempAlle[posten].anzahl,
              vongramm: tempAlle[posten].anzahl * tempAlle[posten].gewicht,
              bisgramm:
                tempAlle[posten].anzahl *
                (tempAlle[posten].gewichtbis > 0
                  ? tempAlle[posten].gewichtbis
                  : tempAlle[posten].gewicht),
              typ: "fisch"
            };
          }
        } else {
          if (typeof sumWare[tempAlle[posten].wareneinheit] != "undefined") {
            sumWare[tempAlle[posten].wareneinheit].count +=
              tempAlle[posten].anzahl;
            sumWare[tempAlle[posten].wareneinheit].vongramm +=
              tempAlle[posten].gewicht;
            sumWare[tempAlle[posten].wareneinheit].bisgramm +=
              tempAlle[posten].gewichtbis > 0
                ? tempAlle[posten].gewichtbis
                : tempAlle[posten].gewicht;
          } else {
            sumWare[tempAlle[posten].wareneinheit] = {
              name: Wareneinheiten.find(tempAlle[posten].wareneinheit)
                .bezeichnung,
              count: tempAlle[posten].anzahl,
              vongramm: tempAlle[posten].gewicht,
              bisgramm:
                tempAlle[posten].gewichtbis > 0
                  ? tempAlle[posten].gewichtbis
                  : tempAlle[posten].gewicht,
              typ: "ware"
            };
          }
        }
      }
      tempAlle = [{ typ: "header" }];
      for (let posten in sumFisch) {
        tempAlle.push(sumFisch[posten]);
      }
      for (let posten in sumWare) {
        tempAlle.push(sumWare[posten]);
      }
      if (tempAlle.length == 1) {
        tempAlle.pop();
      }
      return tempAlle;
    },
    existingOrders() {
      const bestellung = Bestellungen.find(this.currentOrderId);
      const exOrd = Bestellungen.query()
        .where(order => {
          return order.bestellnummer == bestellung.bestellnummer;
        })
        .get();
      return exOrd;
    },
    bestellungen() {
      const sortierungArray = [];
      const statusFilterArray =
        this.filterStatus.length == 0
          ? ["VB", "NV", "OK", "AH", "ZG"]
          : this.filterStatus;
      const startDatum = this.$store.state.bestellungConfig.datum;
      let endDatum = new Date(startDatum);
      endDatum.setDate(
        startDatum.getDate() + (this.listmode == "preview" ? 7 : 1)
      );
      const startString = startDatum.toISOString().substr(0, 10) + " 00:00:00";
      const endString =
        this.listmode == "preview"
          ? endDatum.toISOString().substr(0, 10) + " 23:59:59"
          : endDatum.toISOString().substr(0, 10) + " 02:00:00";
      let tempAlle = Bestellungen.query()
        .where(order => {
          return (
            (order.zustellung || this.filterZustellung) &&
            (order.rueckruf || this.filterRueckruf) &&
            statusFilterArray.includes(order.status) &&
            order.filialeabholung == this.$store.state.bestellungConfig.store &&
            order.datumabholung > startString &&
            order.datumabholung < endString
          );
        })
        .orderBy("datumabholung")
        .get();
      for (let posten in tempAlle) {
        let comparer =
          tempAlle[posten][this.sortBy] +
          tempAlle[posten]["kunde"] +
          tempAlle[posten]["bestellnummer"];
        if (this.sortBy == "kunde") {
          comparer =
            tempAlle[posten][this.sortBy] +
            "," +
            tempAlle[posten]["bestellnummer"];
        }
        if (this.sortBy == "lagernummer") {
          comparer =
            tempAlle[posten]["lagernummer"].toString().padStart(5, "0") +
            tempAlle[posten]["bestellnummer"];
        }
        if (sortierungArray.indexOf(comparer) == -1) {
          sortierungArray.push(comparer);
        }
        tempAlle[posten].abholtag = tempAlle[posten].datumabholung.substr(
          0,
          10
        );
        tempAlle[posten].anzeigename =
          tempAlle[posten].typ == "ware"
            ? Wareneinheiten.find(tempAlle[posten].wareneinheit)
              ? Wareneinheiten.find(tempAlle[posten].wareneinheit).bezeichnung
              : ""
            : Fische.find(tempAlle[posten].fisch)
            ? Fische.find(tempAlle[posten].fisch)[
                "name_" + this.$route.params.lang
              ]
            : "";
      }
      sortierungArray.sort((a, b) => this.sorter(a, b, this.sortBy));
      for (let posten in tempAlle) {
        if (this.sortBy == "datumabholung") {
          tempAlle[posten].groupsorter = sortierungArray.indexOf(
            tempAlle[posten]["datumabholung"] +
              tempAlle[posten]["kunde"] +
              tempAlle[posten]["bestellnummer"]
          );
        }
        if (this.sortBy == "kunde") {
          tempAlle[posten].groupsorter = sortierungArray.indexOf(
            tempAlle[posten]["kunde"] + "," + tempAlle[posten]["bestellnummer"]
          );
        }
        if (this.sortBy == "lagernummer") {
          tempAlle[posten].groupsorter = sortierungArray.indexOf(
            tempAlle[posten]["lagernummer"].toString().padStart(5, "0") +
              tempAlle[posten]["bestellnummer"]
          );
        }
      }
      return tempAlle;
    }
  },
  watch: {},
  beforeDestroy() {},
  methods: {
    getSortClass(nr) {
      switch (nr) {
        case 1:
          return this.sortBy == "datumabholung"
            ? "vdataSort selected"
            : "vdataSort";
        case 2:
          return this.sortBy == "kunde"
            ? "vdataSort second selected"
            : "vdataSort second";
        case 3:
          return this.sortBy == "lagernummer"
            ? "vdataSort third selected"
            : "vdataSort third";
      }
    },
    sorter(a, b, whatBy) {
      if (whatBy == "lagernummer") {
        return a > b ? 1 : a == b ? 0 : -1;
      }
      if (whatBy == "datumabholung") {
        return a > b ? 1 : a == b ? 0 : -1;
      }
      if (whatBy == "kunde") {
        const kundeA = Kunden.find(a.substr(0, a.indexOf(",")));
        const kundeB = Kunden.find(b.substr(0, b.indexOf(",")));
        const kundeAVorname =
          kundeA && kundeA.vorname
            ? kundeA.vorname.toString().toUpperCase()
            : "";
        const kundeANachname =
          kundeA && kundeA.nachname
            ? kundeA.nachname.toString().toUpperCase()
            : "";
        const kundeBVorname =
          kundeB && kundeB.vorname
            ? kundeB.vorname.toString().toUpperCase()
            : "";
        const kundeBNachname =
          kundeB && kundeB.nachname
            ? kundeB.nachname.toString().toUpperCase()
            : "";
        return kundeANachname + kundeAVorname > kundeBNachname + kundeBVorname
          ? 1
          : -1;
      }
    },
    lagernummerBestellnummer() {
      if (this.currentOrderId) {
        return (
          <VRow class="borderBottom mb-3" no-gutters>
            <VCol cols="12" align="right">
              {Bestellungen.find(this.currentOrderId).lagernummer > 0 && (
                <VIcon large class="mr-1 mb-3">
                  mdi-archive
                </VIcon>
              )}
              <span class="text-h4">
                {Bestellungen.find(this.currentOrderId).lagernummer > 0
                  ? (Bestellungen.find(this.currentOrderId).lagernummerzusatz
                      ? Bestellungen.find(this.currentOrderId).lagernummerzusatz
                      : "") +
                    Bestellungen.find(this.currentOrderId)
                      .lagernummer.toString()
                      .padStart(3, "0")
                  : ""}
              </span>
            </VCol>
          </VRow>
        );
      } else {
        return "";
      }
    },
    getSortBy() {
      switch (this.listmode) {
        case "orders":
          return this.sortBy;
        case "prepare":
          return "anzeigename";
        case "preview":
          return "anzeigename";
      }
    },
    getGroupBy() {
      switch (this.listmode) {
        case "orders":
          return "groupsorter";
        case "prepare":
          return "";
        case "preview":
          return "abholtag";
      }
    },
    itemRow(item) {
      const mainrow = [];
      mainrow.push(
        <tr
          class="bestellungRow"
          onClick={() => (
            ((this.currentOrderId = item.item.id),
            (this.currentOrderTyp = item.item.typ)),
            (this.modal = true)
          )}
        >
          <td class="pa-2">
            <VIcon small class="mr-2">
              {item.item.typ == "ware"
                ? "mdi-package-variant-closed"
                : "mdi-fish"}
            </VIcon>
            <span>{item.item.anzeigename}</span>
          </td>
          <td class="pa-2">
            <span>{item.item.anzahl}</span>
          </td>
          <td class="pa-2">
            <span>
              {Fische.find(item.item.fisch) &&
              Fische.find(item.item.fisch).nachgewicht
                ? Intl.NumberFormat("de-DE").format(item.item.gewicht)
                : ""}
            </span>
          </td>
          <td class="pa-2">
            <span>
              {Fische.find(item.item.fisch) &&
              Fische.find(item.item.fisch).nachgewicht
                ? item.item.gewichtbis > 0
                  ? Intl.NumberFormat("de-DE").format(item.item.gewichtbis)
                  : ""
                : ""}
            </span>
          </td>
          <td class="pa-2">
            <div class="kennzeichnungSlot">
              {this.generateFischKZ(item.item.kennzeichnungen).map(item =>
                this.kzButton(item)
              )}
            </div>
          </td>
          <td class="pa-2" align="center">
            <VBtn
              fab
              dark
              small
              color={this.statusIcons[item.item.status].color}
              class={this.statusIcons[item.item.status].class}
            >
              <VIcon>{this.statusIcons[item.item.status].icon}</VIcon>
            </VBtn>
          </td>
        </tr>
      );
      return mainrow;
    },
    sumRow(item) {
      return item.typ == "header" ? (
        <tr class="summePosten header">
          <td align="left">SUMMEN:</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      ) : (
        <tr class="summePosten">
          <td align="left" class="pa-2">
            <VIcon small class="mr-2">
              {item.typ == "ware" ? "mdi-package-variant-closed" : "mdi-fish"}
            </VIcon>
            <span>{item.name}</span>
          </td>
          <td class="pa-2">{item.count}</td>
          <td class="pa-2">
            {item.vongramm > 0
              ? Intl.NumberFormat("de-DE").format(item.vongramm)
              : ""}
          </td>
          <td class="pa-2">
            {item.bisgramm > 0
              ? Intl.NumberFormat("de-DE").format(item.bisgramm)
              : ""}
          </td>
          <td class="pa-2"></td>
          <td class="pa-2"></td>
        </tr>
      );
    },
    groupHeaders(items) {
      const additionalRow = (
        <div class="secondRow">
          <span>
            {(items[0].hauptneben == "H" || items[0].hauptneben == "N") && (
              <VIcon small class="mr-2">
                mdi-silverware-fork-knife
              </VIcon>
            )}

            {items[0].hauptneben == "H" && (
              <VBtn
                disabled
                outlined
                x-small
                class="pa-0 ma-0"
                width="46"
                height="18"
              >
                HAUPT
              </VBtn>
            )}
            {items[0].hauptneben == "N" && (
              <VBtn
                disabled
                outlined
                x-small
                class="pa-0 ma-0"
                width="46"
                height="18"
              >
                NEBEN
              </VBtn>
            )}
          </span>
          <span>
            {items[0].anzahlpersonen && items[0].anzahlpersonen != "" && (
              <VIcon small class="mr-2">
                mdi-account
              </VIcon>
            )}
            {items[0].anzahlpersonen}
          </span>
          <span>{items[0].anmerkung}</span>
        </div>
      );
      switch (this.listmode) {
        case "orders":
          return (
            <td
              ref={
                "b" +
                items[0].lagernummerzusatz +
                items[0].lagernummer.toString().padStart(3, "0")
              }
              colspan="6"
              class={
                this.$store.state.bestellungConfig.lagernummergesamt ==
                items[0].lagernummerzusatz +
                  items[0].lagernummer.toString().padStart(3, "0")
                  ? "ordersGroupHead lastOrder"
                  : "ordersGroupHead"
              }
            >
              <div class="firstRow">
                <VIcon small class="mr-2">
                  mdi-clock-outline
                </VIcon>
                <span>
                  {new Date(items[0].datumabholung).toLocaleTimeString(
                    this.$route.params.lang,
                    this.optionsTime
                  )}
                </span>
                <VIcon
                  class="mr-1"
                  small
                  color={items[0].rueckruf ? "green" : "#bbb"}
                >
                  mdi-phone-classic
                </VIcon>
                <VIcon
                  class="mr-4"
                  small
                  color={items[0].zustellung ? "green" : "#bbb"}
                >
                  mdi-van-utility
                </VIcon>
                <span>
                  {items[0].kunde && Kunden.find(items[0].kunde)
                    ? (Kunden.find(items[0].kunde).nachname &&
                      Kunden.find(items[0].kunde).nachname.length > 0
                        ? Kunden.find(items[0].kunde).nachname + " "
                        : "") +
                      (Kunden.find(items[0].kunde).vorname
                        ? Kunden.find(items[0].kunde).vorname
                        : "")
                    : "-"}
                </span>
                <span>
                  <VIcon small class="mr-2">
                    mdi-archive
                  </VIcon>
                  {(items[0].lagernummerzusatz &&
                  items[0].lagernummerzusatz.substr(0, 4) != "null"
                    ? items[0].lagernummerzusatz
                    : "") +
                    (items[0].lagernummer > 0
                      ? items[0].lagernummer.toString().padStart(3, "0")
                      : "-")}
                </span>
              </div>
              {additionalRow}
            </td>
          );
        case "prepare":
          break;
        case "preview":
          return (
            <td colspan="6" class="ordersGroupHeadPreview">
              <VIcon small class="mr-2">
                mdi-calendar-blank
              </VIcon>
              <span>
                {new Date(items[0].datumabholung).toLocaleDateString(
                  this.$route.params.lang,
                  this.optionsDay
                )}
              </span>
            </td>
          );
      }
    },

    setListMode(what) {
      this.$store.commit("bestellungConfig", {
        key: "listmode",
        value: what
      });
      this.listmode = what;
    },
    currentWeight() {
      if (this.currentOrderId) {
        let returnText = Bestellungen.find(this.currentOrderId).anzahl + " x ";
        if (Bestellungen.find(this.currentOrderId).gewicht > 0) {
          if (Bestellungen.find(this.currentOrderId).gewichtbis > 0) {
            returnText +=
              Bestellungen.find(this.currentOrderId).gewicht +
              "g - " +
              Bestellungen.find(this.currentOrderId).gewichtbis +
              "g";
          } else {
            returnText += Bestellungen.find(this.currentOrderId).gewicht + "+g";
          }
        }
        return returnText;
      } else {
        return "";
      }
    },
    currentName() {
      if (this.currentOrderId) {
        return this.currentOrderTyp == "ware"
          ? Wareneinheiten.find(
              Bestellungen.find(this.currentOrderId).wareneinheit
            )
            ? Wareneinheiten.find(
                Bestellungen.find(this.currentOrderId).wareneinheit
              ).bezeichnung
            : ""
          : Fische.find(Bestellungen.find(this.currentOrderId).fisch)
          ? Fische.find(Bestellungen.find(this.currentOrderId).fisch)[
              "name_" + this.$route.params.lang
            ]
          : "";
      } else {
        return "";
      }
    },
    currentFoto() {
      if (!this.currentOrderId) {
        return "/img/no-image-placeholder.png";
      } else {
        if (this.currentOrderTyp == "ware") {
          return (
            Waren.find(
              Wareneinheiten.find(
                Bestellungen.find(this.currentOrderId).wareneinheit
              ).verbrauchsware
            ).foto || "/img/no-image-placeholder.png"
          );
        } else {
          return (
            (Fische.find(Bestellungen.find(this.currentOrderId).fisch) &&
              Fische.find(Bestellungen.find(this.currentOrderId).fisch).foto) ||
            "/img/no-image-placeholder.png"
          );
        }
      }
    },
    clearStatusFilter() {
      this.$store.commit("bestellungConfig", {
        key: "laststatus",
        value: null
      });
      this.filterStatus = [];
    },
    statusFilterSet(status) {
      this.$store.commit("bestellungConfig", {
        key: "laststatus",
        value: status
      });
      this.filterStatus = [status];
    },
    async orderDelete() {
      const agree = await this.$root.$confirm(
        "Soll dieser Posten entfernt werden?",
        {
          icon: false,
          title: "Posten löschen"
        }
      );
      if (agree) {
        const delId = this.currentOrderId;
        this.modal = false;
        this.currentOrderId = "";
        deleteItem(Bestellungen, delId, "bestellungen");
      }
    },
    orderGoEdit() {
      this.$store.commit("bestellungConfig", {
        key: "rendermode",
        value: "editorder"
      });
      this.$store.commit("bestellungConfig", {
        key: "ordermode",
        value:
          Bestellungen.find(this.currentOrderId).typ == "ware"
            ? "goods"
            : "fish"
      });
      this.$router.push({ path: `./bestellungen/${this.currentOrderId}` });
    },
    maxNumber(filiale, datum) {
      const bestellungen = Bestellungen.query()
        .where(
          "datumabholung",
          value => value.substr(0, 10) == datum.substr(0, 10)
        )
        .where("filialeabholung", filiale)
        .get();
      bestellungen.sort((a, b) => {
        return a.lagernummer < b.lagernummer ? 1 : -1;
      });
      return bestellungen.length > 0 ? bestellungen[0].lagernummer + 1 : 1;
    },
    bestellungAction(action, value) {
      if (action == "status") {
        const ohneLagernummer = ["VB", "NV"];
        const bestellung = Bestellungen.find(this.currentOrderId);
        const alteLagernummer = bestellung.lagernummer;
        let newNumber = this.maxNumber(
          bestellung.filialeabholung,
          bestellung.datumabholung
        );
        if (bestellung.lagernummer == 0) {
          if (ohneLagernummer.indexOf(value) == -1) {
            bestellung.lagernummer = newNumber;
            const lagerKuerzel =
              (Filialen.find(bestellung.filialeabholung).kuerzel
                ? Filialen.find(bestellung.filialeabholung).kuerzel
                : "") + bestellung.datumabholung.substr(8, 2);
            bestellung.lagernummerzusatz = lagerKuerzel;
            for (let index in this.existingOrders) {
              if (this.existingOrders[index].id != this.currentOrderId) {
                Bestellungen.api().put(
                  `/api/bestellungen/${this.existingOrders[index].id}`,
                  {
                    lagernummer: newNumber,
                    lagernummerzusatz: lagerKuerzel
                  },
                  {
                    headers: {
                      "Content-Type": "application/json"
                    }
                  }
                );
              }
            }
          }
        }
        if (
          value != "ZG" ||
          (this.currentOrderId &&
            value == "ZG" &&
            Bestellungen.find(this.currentOrderId).zustellung)
        ) {
          Bestellungen.api().put(
            `/api/bestellungen/${this.currentOrderId}`,
            {
              status: value,
              lagernummer: bestellung.lagernummer,
              lagernummerzusatz: bestellung.lagernummerzusatz
            },
            {
              headers: {
                "Content-Type": "application/json"
              }
            }
          );
          this.modal = false;
          this.neueLagernummer = bestellung.lagernummer
            .toString()
            .padStart(3, "0");
          if (bestellung.lagernummer != alteLagernummer) {
            this.lagermodal = true;
          }
        }
      }
    },
    kzButton(kennzeichnung) {
      return (
        <VBtn
          x-small
          dark
          color="rgba(90, 160, 190, 0.8)"
          elevation="1"
          class="pa-0 px-1 mx-1"
        >
          {Fischkennzeichnungen.find(kennzeichnung).bezeichnung}
        </VBtn>
      );
    },
    generateFischKZ(kzjson) {
      kzjson = kzjson ? kzjson : "[]";
      return JSON.parse(kzjson);
    }
  },
  render() {
    const currentFischKZ =
      this.currentOrderId &&
      this.generateFischKZ(
        Bestellungen.find(this.currentOrderId).kennzeichnungen
      ).map(item => this.kzButton(item));
    const statusChangers = bestellungStatus.map(status => {
      return (
        <VBtn
          width="60"
          height="40"
          class={
            this.currentOrderId &&
            status.key == "ZG" &&
            !Bestellungen.find(this.currentOrderId).zustellung
              ? "mb-3 halfvisible"
              : "mb-3"
          }
          dark
          color={status.color}
          onClick={() => this.bestellungAction("status", status.key)}
        >
          <VIcon>{status.icon}</VIcon>
        </VBtn>
      );
    });

    const statusFilters = bestellungStatus.map(status => {
      return (
        <VBtn
          height="40"
          width="50"
          small
          class="ml-2"
          onClick={() => this.statusFilterSet(status.key)}
          outlined={!this.filterStatus.includes(status.key)}
          dark={this.filterStatus.includes(status.key)}
          color={
            !this.filterStatus.includes(status.key) ? "grey" : status.color
          }
        >
          <VIcon>{status.icon}</VIcon>
        </VBtn>
      );
    });

    const scopedSlots = {
      "body.append": () => {
        return this.bestellungSummen.map(item => this.sumRow(item));
      },
      "group.header": ({ items }) => {
        return this.groupHeaders(items);
      },

      item: item => {
        return this.itemRow(item);
      },
      top: () => {
        return (
          <VRow no-gutters class="pa-3">
            <VDialog
              ref="dialog"
              v-model={this.lagermodal}
              persistent
              width="640"
            >
              <VCard class="pa-4 justify-center">
                <VRow no-gutters>
                  <VCol class="text-h4 pt-5 pb-5" align="center">
                    {this.neueLagernummer == "000"
                      ? this.$t("dialog.storagedelete")
                      : this.$t("dialog.newstorage")}
                  </VCol>
                </VRow>
                <VRow no-gutters>
                  <VCol align="center" class="text-h4 pt-5 pb-5">
                    {this.neueLagernummer != "000" && (
                      <VIcon large class="mr-3 mb-2">
                        mdi-archive
                      </VIcon>
                    )}
                    {this.neueLagernummer != "000" ? this.neueLagernummer : ""}
                  </VCol>
                </VRow>
                <VRow no-gutters>
                  <VCol align="center">
                    <VBtn
                      color="green"
                      dark
                      block
                      onClick={() => (this.lagermodal = false)}
                      class="mt-10"
                    >
                      <VIcon>mdi-check-bold</VIcon>
                    </VBtn>
                  </VCol>
                </VRow>
              </VCard>
            </VDialog>
            <VDialog ref="dialog" v-model={this.modal} persistent width="640">
              <VCard class="pa-4 justify-center">
                {this.lagernummerBestellnummer()}
                <VRow no-gutters>
                  <VCol cols="10">
                    <VRow no-gutters class="bgFisch">
                      <VCol cols="2" class="pa-1">
                        <VImg
                          class="ma-1"
                          src={this.currentFoto()}
                          height="90"
                          width="90"
                          position="left top"
                          contain
                        ></VImg>
                      </VCol>
                      <VCol cols="10" class="bestellungFishHead pa-2">
                        <strong>{this.currentName()}</strong>
                        <span>{this.currentWeight()}</span>
                        <div class="mt-1">{currentFischKZ}</div>
                      </VCol>
                    </VRow>
                    <VRow no-gutters class="bgFisch">
                      <VCol cols="12" class="bestellungFishHead pa-2">
                        <div class="smallerText14">
                          {this.currentOrderId &&
                            Bestellungen.find(this.currentOrderId).anmerkung}
                        </div>
                        <div class="text-caption">
                          {this.currentOrderId &&
                            Bestellungen.find(this.currentOrderId).hauptneben ==
                              "H" && (
                              <VBtn disabled outlined x-small class="px-2 mr-2">
                                HAUPT
                              </VBtn>
                            )}
                          {this.currentOrderId &&
                            Bestellungen.find(this.currentOrderId) == "N" && (
                              <VBtn disabled outlined x-small class="px-2 mr-2">
                                NEBEN
                              </VBtn>
                            )}
                          {this.currentOrderId &&
                            Bestellungen.find(this.currentOrderId)
                              .anzahlpersonen &&
                            Bestellungen.find(this.currentOrderId)
                              .anzahlpersonen != "" && (
                              <VIcon small class="mr-2">
                                mdi-silverware-fork-knife
                              </VIcon>
                            )}
                          {this.currentOrderId &&
                            Bestellungen.find(this.currentOrderId)
                              .anzahlpersonen}
                        </div>
                      </VCol>
                    </VRow>
                    <VRow no-gutters class="bgKunde">
                      <VCol cols="2" class="pa-2">
                        <VIcon
                          class="mx-2"
                          color={
                            this.currentOrderId &&
                            Bestellungen.find(this.currentOrderId).rueckruf
                              ? "green"
                              : "#eee"
                          }
                        >
                          mdi-phone-classic
                        </VIcon>
                        <VIcon
                          class="mx-2"
                          color={
                            this.currentOrderId &&
                            Bestellungen.find(this.currentOrderId).zustellung
                              ? "green"
                              : "#eee"
                          }
                        >
                          mdi-van-utility
                        </VIcon>
                      </VCol>
                      <VCol cols="10" class="pa-2">
                        {this.kundeAktuell}
                      </VCol>
                    </VRow>
                    <VRow no-gutters class="mt-8">
                      <VCol cols="12">
                        <VBtn
                          width="60"
                          height="40"
                          class="mr-3"
                          large
                          dark
                          color="red"
                          onClick={() => (this.modal = false)}
                        >
                          <VIcon class="mr-2">mdi-arrow-left-thick</VIcon>
                          <VIcon large>mdi-view-list</VIcon>
                        </VBtn>
                        <VBtn
                          width="60"
                          height="40"
                          large
                          dark
                          color="orange"
                          class="mr-3"
                          onClick={() => this.orderDelete()}
                        >
                          <VIcon large>mdi-delete</VIcon>
                        </VBtn>

                        <VBtn
                          width="60"
                          height="40"
                          large
                          dark
                          color="orange darken-3"
                          onClick={() => this.orderGoEdit()}
                        >
                          <VIcon>mdi-pencil</VIcon>
                        </VBtn>
                      </VCol>
                    </VRow>
                  </VCol>
                  <VCol cols="2" align="right">
                    {statusChangers}
                  </VCol>
                </VRow>
              </VCard>
            </VDialog>
            <VCol class="d-flex justify-start">
              <VBtn
                width="75"
                height="40"
                onClick={() => this.setListMode("orders")}
                outlined={this.listmode == "orders" ? false : true}
                dark={this.listmode == "orders" ? true : false}
                color={this.listmode == "orders" ? "rgb(0, 113, 113)" : "grey"}
                small
                class="mr-2"
              >
                <VIcon>mdi-clipboard-list</VIcon>
              </VBtn>
              <VBtn
                width="75"
                height="40"
                onClick={() => this.setListMode("prepare")}
                outlined={this.listmode == "prepare" ? false : true}
                dark={this.listmode == "prepare" ? true : false}
                color={this.listmode == "prepare" ? "rgb(0, 113, 113)" : "grey"}
                small
                class="mr-2"
              >
                <VIcon>mdi-store</VIcon>
              </VBtn>
              <VBtn
                width="75"
                height="40"
                onClick={() => this.setListMode("preview")}
                outlined={this.listmode == "preview" ? false : true}
                dark={this.listmode == "preview" ? true : false}
                color={this.listmode == "preview" ? "rgb(0, 113, 113)" : "grey"}
                small
                class="mr-2"
              >
                <VIcon>mdi-calendar-multiple-check</VIcon>
              </VBtn>
              {this.listmode == "orders" && (
                <VBtn
                  onClick={() => (this.sortBy = "datumabholung")}
                  width="36"
                  height="36"
                  small
                  class={this.getSortClass(1)}
                >
                  <VIcon small>mdi-sort-clock-ascending</VIcon>
                </VBtn>
              )}
              {this.listmode == "orders" && (
                <VBtn
                  onClick={() => (this.sortBy = "kunde")}
                  width="36"
                  height="36"
                  small
                  class={this.getSortClass(2)}
                >
                  <VIcon small>mdi-sort-alphabetical-descending-variant</VIcon>
                </VBtn>
              )}
              {this.listmode == "orders" && (
                <VBtn
                  onClick={() => (this.sortBy = "lagernummer")}
                  width="36"
                  height="36"
                  small
                  class={this.getSortClass(3)}
                >
                  <VIcon small>mdi-archive-arrow-down</VIcon>
                </VBtn>
              )}
            </VCol>
            <VCol cols="3" class="d-flex justify-start pr-10">
              <FilialeAuswahl />
            </VCol>
            <VCol class="d-flex justify-end">
              <VBtn
                width="60"
                height="40"
                onClick={() => (
                  (this.filterZustellung = true), (this.filterRueckruf = true)
                )}
                outlined={!this.filterZustellung || !this.filterRueckruf}
                dark={this.filterZustellung || this.filterRueckruf}
                color={
                  !this.filterZustellung || !this.filterRueckruf
                    ? "grey"
                    : "rgb(0, 113, 113)"
                }
                small
                class="mr-2"
              >
                <VIcon>mdi-filter-off</VIcon>
              </VBtn>
              <VBtn
                width="50"
                height="40"
                onClick={() => (
                  (this.filterRueckruf = true),
                  (this.filterZustellung = !this.filterZustellung)
                )}
                outlined={this.filterZustellung}
                dark={!this.filterZustellung}
                color={this.filterZustellung ? "grey" : "rgb(0, 113, 113)"}
                small
                class="mr-2"
              >
                <VIcon>mdi-van-utility</VIcon>
              </VBtn>
              <VBtn
                width="50"
                height="40"
                onClick={() => (
                  (this.filterZustellung = true),
                  (this.filterRueckruf = !this.filterRueckruf)
                )}
                outlined={this.filterRueckruf}
                dark={!this.filterRueckruf}
                color={this.filterRueckruf ? "grey" : "rgb(0, 113, 113)"}
                small
                class="mr-15"
              >
                <VIcon>mdi-phone-classic</VIcon>
              </VBtn>
              <VBtn
                width="50"
                height="40"
                onClick={() => this.clearStatusFilter()}
                outlined={this.filterStatus.length > 0}
                dark={this.filterStatus.length == 0}
                color={
                  this.filterStatus.length > 0 ? "grey" : "rgb(0, 113, 113)"
                }
                small
              >
                <VIcon>mdi-filter-off</VIcon>
              </VBtn>

              {statusFilters}
            </VCol>
          </VRow>
        );
      }
    };
    return (
      <VDataTable
        class={this.listmode}
        itemKey="id"
        items={this.bestellungen}
        headers={this.headers}
        custom-filter={this.filterListe}
        scopedSlots={scopedSlots}
        group-by={this.getGroupBy()}
        disable-pagination
        hide-default-footer
        height="calc(100vh - 180px)"
        sort-by={this.getSortBy()}
        fixed-header
        sortDesc={this.sortDesc}
      />
    );
  }
};
