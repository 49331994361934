export default (model, vm) => {
  const fields = model.fields();
  const values = {};
  const item = model.find(vm.$route.params.id);
  for (const field in fields) {
    if (field !== "id") {
      values[field] = item ? item[field] : undefined;
    }
  }
  return values;
};
