import Fisch from "@/models/fisch";
import Kunden from "@/models/kunden";
import Users from "@/models/users";
import Laendernamen from "@/models/laendernamen";
import Lieferanten from "../models/lieferanten";
import Wareneinheiten from "@/models/wareneinheiten";
import Fanggebiete from "@/models/fanggebiete";
import Fangmethoden from "@/models/fangmethoden";
import Filialen from "@/models/filialen";
import Fischeinheiten from "@/models/fischeinheiten";
import Fischkennzeichnungen from "@/models/fischkennzeichnungen";
import Verbrauchswaren from "@/models/verbrauchswaren";
import Mitarbeiter from "@/models/mitarbeiter";
import Bestellungen from "@/models/bestellungen";
import Einkauf from "@/models/einkauf";

export default {
  fische: Fisch,
  kunden: Kunden,
  users: Users,
  laendernamen: Laendernamen,
  lieferanten: Lieferanten,
  wareneinheiten: Wareneinheiten,
  fanggebiete: Fanggebiete,
  fangmethoden: Fangmethoden,
  filialen: Filialen,
  fischeinheiten: Fischeinheiten,
  fischkennzeichnungen: Fischkennzeichnungen,
  verbrauchswaren: Verbrauchswaren,
  mitarbeiter: Mitarbeiter,
  bestellungen: Bestellungen,
  einkauf: Einkauf
};
