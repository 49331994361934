import {
  VForm,
  VCard,
  VRow,
  VCol,
  VBtn,
  VTextField,
  VCheckbox,
  VTextarea,
  VIcon,
  VAutocomplete
} from "vuetify/lib";

import "../detailTables.scss";
import Kunden from "@/models/kunden";
import Laendernamen from "@/models/laendernamen";
import bindings from "@/store/bindings";
import { persistItem, deleteItem } from "@/utils/helpers";
import "./mobileClasses.scss";

export default {
  data() {
    return {
      // this will automatically be updated by the setters of v-model properties
      ...bindings(Kunden, this),
      dirty: false,
      allLaender: [],
      anredeValues: ["Herr", "Frau", "Familie"],
      defaults: {
        gruppe: "Verkäuferanlage",
        istfirma: false,
        ust: true,
        ustprozent: 20
      }
    };
  },
  computed: {
    rules() {
      return {
        required: value => !!value || this.$t("error.obligatory"),
        plz: value =>
          (value >= 1000 && value <= 9999) || this.$t("error.areacode"),
        minlength: value => value.length > 4 || this.$t("error.chars5min"),
        email: value => {
          if (value) {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return (
              pattern.test(value) ||
              value.length === 0 ||
              this.$t("error.invalidmail")
            );
          } else {
            return true;
          }
        }
      };
    }
  },
  mounted() {
    this.$watch(
      "$data",
      function () {
        this.dirty = true;
      },
      {
        deep: true
      }
    );
    Laendernamen.all().forEach(element =>
      this.allLaender.push({ value: element.id, text: element.name_de })
    );
    if (!Kunden.find(this.$route.params.id)) {
      for (let field in this.defaults) {
        this[field] = this.defaults[field];
      }
    }
  },
  methods: {
    closeEdit() {
      if (this.$store.state.bestellungConfig.rendermode != "") {
        this.$router.push({
          path:
            "/" +
            this.$route.params.lang +
            "/mobile/bestellungen/" +
            this.$store.state.bestellungConfig.quicksave.id
        });
      } else {
        this.$router.push({
          path: "/" + this.$route.params.lang + "/mobile/verkauf"
        });
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        const id = this.$route.params.id;
        this.istfirma = !!this.istfirma; // prevent undefined
        this.ust = !!this.ust; // prevent undefined
        persistItem(Kunden, id, this, "kunden");
        this.$store.commit("bestellungConfig", {
          key: "customer",
          value: id
        });
        if (this.$store.state.bestellungConfig.rendermode != "") {
          const quickSave = this.$store.state.bestellungConfig.quicksave;
          quickSave.kunde = id;
          this.$store.commit("bestellungConfig", {
            key: "quicksave",
            value: quickSave
          });

          this.$router.push({
            path:
              "/" +
              this.$route.params.lang +
              "/mobile/bestellungen/" +
              this.$store.state.bestellungConfig.quicksave.id
          });
        } else {
          this.$router.push({
            path: "/" + this.$route.params.lang + "/mobile/verkauf"
          });
        }
      }
    },
    delete() {
      const id = this.$route.params.id;
      deleteItem(Kunden, id, "kunden");
      this.$router.push({ path: "../kunden" });
    }
  },
  render() {
    return (
      <VCard
        color="rgba(255,255,255,.9)"
        width="100%"
        elevation="5"
        class="fill-height ma-0 pa-0"
      >
        <VRow no-gutters class="heading">
          <VCol cols="2" class="pa-2">
            <VBtn fab small>
              <VIcon color="grey">mdi-card-account-details</VIcon>
            </VBtn>
          </VCol>
          <VCol cols="10" class="pa-2 d-flex justify-end">
            <span class="white--text text-h6 ma-1">
              {this.$t("menu.customers") + " / " + this.$t("header.details")}
            </span>
          </VCol>
        </VRow>
        <VForm ref="form" lazy-validation>
          <VRow no-gutters class="kundeneditFillHeight">
            <VCol cols="6">
              <VAutocomplete
                hide-details
                class="ma-2 whiteBack"
                dense
                outlined
                v-model={this.anrede}
                items={this.anredeValues}
                label={this.$t("label.title")}
              ></VAutocomplete>
              <VTextField
                hide-details
                class="ma-2 whiteBack"
                dense
                outlined
                v-model={this.vorname}
                label={this.$t("label.firstname")}
              ></VTextField>
              <VTextField
                hide-details
                class="ma-2 whiteBack"
                dense
                outlined
                v-model={this.nachname}
                label={this.$t("label.lastname")}
                rules={[this.rules.required]}
              ></VTextField>
              <VRow>
                <VCol cols="1">
                  <VCheckbox
                    class="ma-2"
                    v-model={this.istfirma}
                    label=""
                  ></VCheckbox>
                </VCol>
                <VCol cols="11">
                  <VTextField
                    hide-details
                    class="ma-2 whiteBack"
                    dense
                    outlined
                    v-model={this.firmenname}
                    label={this.$t("label.companyname")}
                  ></VTextField>
                </VCol>
              </VRow>
              <VTextarea
                hide-details
                class="ma-2 whiteBack"
                dense
                outlined
                rows="3"
                v-model={this.anmerkung}
                label={this.$t("label.remark")}
              ></VTextarea>
            </VCol>
            <VCol cols="6">
              <VTextField
                hide-details
                class="ma-2 whiteBack"
                dense
                outlined
                v-model={this.mobil}
                label={this.$t("label.mobile")}
              ></VTextField>
              <VTextField
                hide-details
                class="ma-2 whiteBack"
                dense
                outlined
                v-model={this.email}
                label={this.$t("label.email")}
                rules={[this.rules.email]}
              ></VTextField>

              <VTextField
                hide-details
                class="ma-2 whiteBack"
                dense
                outlined
                v-model={this.adresse}
                label={this.$t("label.address")}
              ></VTextField>
              <VTextField
                hide-details
                class="ma-2 whiteBack"
                dense
                outlined
                v-model={this.adresse2}
                label={this.$t("label.address") + " 2"}
              ></VTextField>
              <VTextField
                hide-details
                class="ma-2 whiteBack"
                dense
                outlined
                v-model={this.plz}
                type="number"
                label={this.$t("label.areacode")}
              ></VTextField>
              <VTextField
                hide-details
                class="ma-2 whiteBack"
                dense
                outlined
                v-model={this.ort}
                label={this.$t("label.city")}
              ></VTextField>
              <VAutocomplete
                hide-details
                class="ma-2 whiteBack"
                dense
                outlined
                items={this.allLaender}
                v-model={this.land}
                label={this.$t("label.country")}
              ></VAutocomplete>
            </VCol>
          </VRow>
        </VForm>
        <VRow class="mt-2 bottomRow" no-gutters>
          <VCol cols="4" class="pa-1">
            <VBtn
              width="100%"
              large
              color="red"
              dark
              onClick={() => this.closeEdit()}
            >
              <VIcon large>mdi-close-thick</VIcon>
            </VBtn>
          </VCol>
          <VCol cols="4" class="pa-1"></VCol>
          <VCol cols="4" class="pa-1">
            <VBtn width="100%" large color="green" dark onClick={this.save}>
              <VIcon large>mdi-check-bold</VIcon>
            </VBtn>
          </VCol>
        </VRow>
      </VCard>
    );
  }
};
